#edit-settings {
  .fieldset-wrapper {
    flex-direction: column;

    .form-item {
      width: 100% !important;
    }
  }
}

#form-webpack-map-config {
   margin: 50px 22px;

  > div {

    @include breakpoint($tab) {
      width: calc(100% + 40px);
      display: flex;
      flex-wrap: wrap;
      margin: 0 -20px;
    }

    .form-wrapper {
      width: 100%;

      .fieldset-wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 0 16px;

        > .form-item {
          padding: 0 20px;
          margin: 0 0 40px;

          @include breakpoint($tab) {
            width: 50%;
          }

          @include breakpoint($desk) {
            width: 25%;
          }
        }
      }

      &#edit-main-ccaa {
        .fieldset-wrapper {
          > .form-item {
            width: 100%;

            .selectize-control {
              width: 100%;
              padding-bottom: 16px;

              @include breakpoint($tab) {
                width: 300px;
              }
            }
          }
        }
      }
    }

    > .form-submit {
      @include btn-red-border;
      width: 100%;
      height: 40px;
      align-self: center;
      // margin: 0 20px;

      @include breakpoint($tab) {
        width: 15%;
      }
    }
  }
}

