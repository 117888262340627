.node-blog_authors-form {
  padding: 30px 0;

  .col {

    > div:not(.form-actions):not(.vertical-tabs) {
      margin: 0 0 2.5em;
    }

    > .field-type-link-field,
    > .field-type-image {
      border: 1px solid;
      padding: 0.5em;
      margin-bottom: 2em;

      > div {
        padding: 0 10px;
      }

    }

  }

  .form-type-managed-file {

    > label {
      margin-bottom: 7px;
    }

    .file-widget,
    .image-widget {
      margin-bottom: 5px;

      input {
        font-size: 14px;
        padding: 5px;
      }

    }

  }

  .filter-wrapper {
    padding: 0;
  }

  #edit-comment {

    > div {
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }

    }
  }

}
