.page-events {

  .region-container-content {

    > div > .region-content {

      @include breakpoint ($desk) {
        padding-left: 4.5%;
      }

    }

    .col-md-8 {

      @include breakpoint ($tab) {
        @media screen and (-webkit-min-device-pixel-ratio: 0) {
          flex: 0 0 65%;
          max-width: 65%;
        }

      }
    }
  }

}
