.node-webpack-digital-demo.node-teaser {
  text-align: center;
  padding: 0 10px 40px;
  margin-bottom: 30px;

  .content a {
    display: inline-block;

    .field-name-field-webpack-dd-image {
      margin-bottom: 10px;

      img {
        vertical-align: top;
      }

    }

    > h4 {
      font-size: 18px;
      margin: 0;
    }

  }

}
