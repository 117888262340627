.paragraphs-item-map {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
