.view-events-by-month {
  position: relative;

  .view-header {
    width: calc(100% - 55px);
    text-align: right;
    border-bottom: 1px solid $color-grey-2;
    line-height: .7;
    padding: 0 5px 4px;

    .date-nav-wrapper {
      width: initial;
      display: inline-block;
      margin: 0;
    }

    .date-nav {
      padding: 0;
      margin: 0;
    }

    .date-heading {
      text-align: left;
      padding: 0 30px;

      h3 {
        color: $color-grey-1;
        font-size: 24px;
        line-height: 1;
        text-transform: uppercase;
      }

    }

    .pager {
      margin: 0;

      li {
        line-height: 2;
        padding: 0;
        margin: 0;
      }

      .date-prev {

        a {
          transform: rotate(180deg);
          text-indent: -1000em;
        }

      }

      .date-next {

        a {
          text-indent: 1000em;
        }

      }

      a {
        display: block;
        width: 20px;
        height: 25px;
        background: url(image-url("icons/chevron-red-fine.svg")) no-repeat center;
        background-size: contain;
        overflow: hidden;
        padding: 0.5em;
        margin: 0;

        &:hover {
          opacity: .6;
        }

      }
    }

  }

  .view-empty {
    display: table;
    width: 100%;
    height: 120px;

    p {
      display: table-cell;
      font-size: 22px;
      vertical-align: middle;
      text-align: center;
      padding-right: 55px;
    }

  }

  .view-content {
    // To enable the scroll we need assign a height.
    height: 375px;

    .nano-content {
      padding-right: 55px;
    }

    .nano-pane {
      width: 21px;
      height: calc(100% - 33px);
      visibility: visible;
      background: url(image-url("icons/dotted-line-grey.svg")) repeat-y center;

      .nano-slider {
        background: white;

        &:before {
          content: '';
          width: 20px;
          height: 20px;
          background: $color-red-1;
          border-radius: 50%;
          transition: background 250ms;
          cursor: pointer;
          transform: translate(0, -50%);
          position: absolute;
          top: 50%;
          left: 0;
        }

      }

    }

    .views-row {
      border-bottom: 1px solid $color-grey-2;
      padding: 11px 0 13px;

      &:last-child {
        border-bottom: none;
      }

    }
  }

}
