.node--webpack-project--full {
  padding-top: 40px;
  position: relative;

  > h2 {
    color: $color-red-1;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    margin: 20px 0 10px;

    @include breakpoint ($desk) {
      font-size: 30px;
    }

    a {
      color: $color-red-1;
    }

    span {
      padding: 0 4px;

      &:first-child {
        padding-left: 0;
      }

    }

    div {
      color: $color-red-1;
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
      margin: 0;
      position: relative;

      @include breakpoint ($desk) {
        font-size: 26px;
      }
    }

  }

  .catalogue-wrapper {
    text-align: center;
    margin-bottom: 40px;

    a {
      display: inline-block;
      color: black;
      font-family: $font-exo2;
      font-size: 16x;
      padding: 9px 20px 9px 20px;
      color: $color-grey-1-5;
      -webkit-box-shadow: 0 5px 5px -2px #c7c7c7;
      -moz-box-shadow: 0 5px 5px -2px #c7c7c7;
      box-shadow: 0 5px 5px -2px #c7c7c7;
      text-align: center;
      cursor: pointer;
      transition: all 250ms;
      border: 1px solid transparent;
      margin-top: 15px;

      &:hover {
        color: $color-red-2;
        border-color: $color-red-2;
        border-radius: 3px;
      }

    }

  }

  > .content {
    margin-top: 30px;

    .heading-wrapper {
      text-align: center;
      clear: both;
      margin-bottom: 30px;
    }

    .embedded-video {
      max-width: 640px;
      margin: 0 auto;

      .player {
        max-width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        overflow: hidden;
        position: relative;

        iframe {
          width: 100%;
          height: 100%;
          transform: translateX(-50%);
          position: absolute;
          top: 0;
          left: 50%;
        }

      }

    }

    .field-name-field-webpack-project-img-detail {

      img {
        vertical-align: top;

        @include breakpoint ($desk) {
          min-width: 600px;
        }

      }

    }

    .contact-wrapper {
      margin-bottom: 50px;

      a {
        display: inline-block;
        color: $color-red-1;
        font-size: 16px;
        background-image: url(image-url("icons/icon-contact-red.png"));
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 60px;
        text-decoration: none;
        cursor: pointer;
        transition: all 250ms;
        padding-top: 65px;

        &:hover {
          color: $color-red-3;
          background-image: url(image-url("icons/icon-contact-hover.png"));
        }

      }

    }

    .group-related {
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;
      margin-top: 50px;

      @include breakpoint ($desk) {
        flex-direction: row;
        justify-content: space-between;
        padding: 0;
      }

      .book-group {
        position: relative;

        .open-modal {

          &:hover {

            + .book-list {
              position: static;
              pointer-events: all;
              opacity: 1;
              visibility: visible;

              @include breakpoint ($desk) {
                position: absolute;
              }

            }
          }

        }

        .book-list {
          min-width: 20vw;
          width: 100%;
          opacity: 0;
          visibility: hidden;
          transition: all .4s;
          pointer-events: none;
          margin-top: -12px;
          border-left: 1px solid $color-red-2;
          border-right: 1px solid $color-red-2;
          border-bottom: 1px solid $color-red-2;
          margin-bottom: 10px;
          position: absolute;
          left: 0;
          bottom: 0;

          @include breakpoint ($desk) {
            border: none;
            transform: translateX(-50%);
            padding-bottom: 20px;
            margin-top: 0;
            margin-bottom: 0;
            left: 50%;
            bottom: 100%;
          }

          &:hover {
            position: static;
            pointer-events: all;
            visibility: visible;
            opacity: 1;

            @include breakpoint ($desk) {
              position: absolute;
            }

          }

          &:before {
            $width: 20px;
            display: inline-block;
            width: $width;
            height: $width;
            background: $color-grey-5;
            transform: translateX(-50%) rotate(135deg);
            position: absolute;
            left: 50%;
            bottom: 11px;

            @include breakpoint ($desk) {
              content: "";
            }

          }

          ul {
            background: $color-grey-5;
            padding: 15px;
            margin: 0;

            li {
              text-align: center;
              list-style: none;
              margin: 0 0 10px;

              &:last-child {
                margin-bottom: 0;
              }

            }
          }
        }

      }

      > div > a,
      > a {
        padding: 3px 30px 0 30px;
        color: $color-grey-1-5;
        background: $color-grey-5;
        min-height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        border: 1px solid transparent;
        -webkit-box-shadow: 0 5px 5px -2px #c7c7c7;
        -moz-box-shadow: 0 5px 5px -2px #c7c7c7;
        box-shadow: 0 5px 5px -2px #c7c7c7;
        text-align: center;
        cursor: pointer;
        transition: all 250ms;
        font-family: $font-exo2;
        text-transform: none;
        margin: 0 0 10px;

        &:hover {
          color: $color-red-2;
          border-color: $color-red-2;
          border-radius: 3px;
        }

        @include breakpoint ($desk) {
          margin: 0;
        }
      }
    }
  }

}
