div[id^="block-menu-menu-footer-legals"] {
  line-height: 0.6;

  > .content {
    text-align: center;

    @include breakpoint ($desk) {
      text-align: left;
    }

    ul:not(.contextual-links) {
      @include clearfix;
      text-align: center;
      display: inline-block;
      padding: 0;
      margin: 0;

      @include breakpoint ($desk) {
        text-align: left;
      }

      > li {
        display: inline-block;
        color: white;
        font-size: 11px;
        font-weight: 300;
        font-family: $font-fira-sanz-condensed;
        line-height: 1;
        text-transform: uppercase;
        list-style: none;
        padding: 0;
        margin: 0;

        @include breakpoint (365px) {
          border-right: 1px solid white;
        }

        @include breakpoint ($tab) {
          font-size: 14px;
          text-transform: initial;
          float: left;
          border-right: 1px solid white;
          margin: 0;
        }

        @include breakpoint ($desk) {
          font-size: 16px;
          display: block;
          float: none;
          border-right: none;
          margin-bottom: 4px;
        }

        &:nth-child(odd) {
          border-right: 1px solid white;

          @include breakpoint ($desk) {
            border-right: none;
          }

        }

        &:last-child {
          border-right: none;
          padding-left: 2px;
          padding-right: 0;

          @include breakpoint ($tab) {
            padding-left: 5px;
          }

          @include breakpoint ($desk) {
            margin-bottom: 0;
          }

        }

        a {
          display: inline-block;
          color: white;
          font-size: 11px;
          font-family: $font-fira-sanz-condensed;
          font-weight: 300;
          line-height: 1;
          text-transform: uppercase;
          padding-left: 1px;
          padding-right: 5px;

          @include breakpoint ($tab) {
            font-size: 16px;
            text-transform: initial;
            padding-left: 5px;
            padding-right: 5px;
          }

        }

        span {
          display: inline-block;
          @include breakpoint ($tab) {
            padding-top: 2px;
          }

        }
      }
    }
  }

}
