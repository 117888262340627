.view-books-catalog {
  .views-exposed-form {
    display: none;
    position: relative;
    background: $color-grey-5;
    border-radius: 5px;
    margin-bottom: 16px;
    padding: 8px 16px;

    #edit-search-api-views-fulltext-wrapper,
    #edit-field-book-catalog-wrapper {
      display: none;
    }

    .views-exposed-widget {
      padding: 0.5em 1em 0;

      .selectize-input {
        box-shadow: none;
      }

      .form-item-sort-by {
        min-width: 200px;
      }

      #edit-submit-books-catalog {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;

        @include btn-red-border;
      }
    }
  }

  .views-row {
    margin-bottom: 40px;

    @include breakpoint($tab) {
      margin-bottom: 30px;
    }

    &:last-child {
      margin-bottom: 0;
    }

  }

}

.views-filter-orderby {
  position: relative;
  margin-bottom: 50px;

  nav {
    position: absolute;
    z-index: 1;
    right: 0;

    .menu {
      padding: 0;
      width: 200px;
      margin: 0;

      li {
        list-style: none;
        background-color: $color-grey-3;
        font-size: 14px;
        line-height: 2;

        span {
          margin: 0 10px;

          &:after {
            content: '';
            position: absolute;
            width: 13px;
            height: 10px;
            background: url(image-url("icons/chevron-black-bold.svg")) no-repeat;
            transform: translate(0, 0) rotate(180deg);
            right: 5px;
            border: none;
            transition: all .4s;
            margin-top: 7px;
          }
        }

      }

      &:hover {

        .sub-menu {
          display: block;
        }

        li {

          span {

            &:after {
              transform: translate(0, 0) rotate(0);
            }

          }
        }

      }

      .sub-menu {
        padding: 0;
        display: none;

        li {
          list-style: none;
          margin: 0;

          &:hover {
            background-color: $color-grey-5;
          }

          a {
            display: block;
            font-size: 14px;
            font-weight: normal;
            margin: 0 10px;

            &:hover {
              color: $color-grey-1;
            }

          }

        }
      }
    }

    .region-content {
      margin-top: 50px;
    }
  }
}
