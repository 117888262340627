.form-select {

  &.multi {
    min-width: 300px;

    .selectize-input {
      border: 1px solid #A6A6A6;
      border-radius: 0;
      margin-top: 8px;
    }

    .selectize-input {

      .item {
        color: white;
        background: $color-red-1;
        border: none;
        border-radius: 0;
      }

    }

    .selectize-dropdown {

      .option {
        font-size: 16px;
        line-height: 1.5;
        cursor: pointer;

        &:hover {
          color: white;
          background: $color-red-1;
        }

      }
    }
  }

}

.term-reference-tree-level {

  > li {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .term-reference-tree-level {
      width: 100%;
      margin-top: 10px;
    }

  }

  .term-reference-tree-button {
    width: 23px;
    height: 23px;
    border: 2px solid black;
    background: transparent;
    transition: all .4s;
    cursor: pointer;
    margin-right: 10px;
    position: relative;

    &:hover {
      background: $color-grey-4;
    }

    &:before {
      content: "-";
      display: inline-block;
      font-size: 32px;
      font-weight: 500;
      line-height: 1px;
      position: absolute;
      top: 10px;
      left: 3px;
    }

    &.term-reference-tree-collapsed {

      &:before {
        content: "+";
        left: 2px;
      }

    }

  }

  .form-type-checkbox input[type="checkbox"]:not(old):not(.vbo-select):not(.vbo-table-select-all):checked + label:before {
    background: black;
  }

}

.form-type-checkbox {
  @include checkbox;
}

.vertical-tabs .vertical-tabs-panes {
  padding-top: 15px;

  .form-type-select {
    display: block !important;
  }

  .fieldset-wrapper > div {
    margin-bottom: 1.5em;

    .form-item {
      margin-bottom: 1.5em;
    }

  }

}

.field-type-image label,
.field-type-file label {
  margin-bottom: 5px;
}
