@mixin btn-red-border {
  color: $color-red-1;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: transparent;
  border: 2px solid $color-red-1;
  cursor: pointer;
  transition: all 250ms;
  padding: 6px 29px 4px;

  &:hover {
    color: white;
    background-color: $color-red-1;
  }
}
