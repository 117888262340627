*, *:before, *:after {
  box-sizing: border-box;
}

html {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  font-size: 18px;
  font-family: $font-fira-sanz-condensed;
  overflow-x: hidden;
  line-height: 1.5em;
  margin: 0;

  @include breakpoint ($desk) {
    font-size: 20px;
  }

}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

a {
  color: $color-grey-1;
  font-size: 18px;
  font-family: $font-fira-sanz-condensed;
  font-weight: 600;
  line-height: 1.5em;
  text-decoration: none;
  outline: none;
  transition: all 250ms;

  @include breakpoint ($desk) {
    font-size: 20px;
  }

  &:hover {
    color: $color-red-2;
  }

}

p a {
  color: $color-red-1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-exo2;
  font-weight: 400;
  line-height: 1;
}

h1 {
  font-size: 36px;

  @include breakpoint ($desk) {
    font-size: 48px;
  }

}

h2 {
  font-size: 30px;

  @include breakpoint ($desk) {
    font-size: 42px;
  }

}

h3 {
  font-size: 24px;

  @include breakpoint ($desk) {
    font-size: 36px;
  }

}

h4 {
  font-size: 20px;

  @include breakpoint ($desk) {
    font-size: 30px;
  }

}

h5 {
  font-size: 18px;

  @include breakpoint ($desk) {
    font-size: 24px;
  }

}

h6 {
  font-size: 16px;

  @include breakpoint ($desk) {
    font-size: 18px;
  }

}

strong {
  font-weight: bold;
}

.field-type-text-long div {

  > h1:first-child,
  > h2:first-child,
  > h3:first-child,
  > h4:first-child,
  > h5:first-child,
  > h6:first-child,
  > p:first-child {
    margin-top: 0;
  }

}

table {
  width: 100%;
}

.only-mobile {

  @include breakpoint ($desk) {
    display: none !important;
  }

}

.only-desktop {
  display: none !important;

  @include breakpoint ($desk) {
    display: initial !important;
  }

}

// Generic link style in some body fields.
.field-name-field-news-body,
.field-name-field-article-body,
.field-name-field-event-body,
.field-name-field-basic-page-body {
  a {
    color: $color-red-3;
    font-weight: 600;

    &:hover {
      color: $color-red-2;
    }

  }
}

caption {
  font-size: 14px;
  font-family: $font-fira-sanz-condensed;
}
