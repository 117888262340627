.node-article.ct-as-landing-page-component {
  position: relative;

  &:hover {

    > .hover-content {
      opacity: 1;
    }

    > .content {
      > h2 {
        opacity: 0;
      }
    }
  }

  > .content {

    > h2 {
      width: 100%;
      color: $color-grey-1;
      font-size: 20px;
      font-weight: initial;
      line-height: 1.2;
      background: rgba(255, 255, 255, 0.8);
      transition: all 500ms;
      padding: 11px 18px 12px;
      margin: 0;
      position: absolute;
      bottom: -1px;
      left: 0;

      @include breakpoint ($desk) {
        font-size: 23px;
        line-height: 1.3;
        padding: 25px 30px 30px;
      }

    }

    img {
      width: 100%;
      vertical-align: top;
    }
  }

  > .hover-content {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    background: rgba(255, 255, 255, 0.8);
    transition: all 500ms;
    z-index: 1;
    opacity: 0;
    padding: 12px 16px 60px;
    position: absolute;
    top: 10px;
    left: 10px;

    @include breakpoint ($desk) {
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      padding: 37px 43px 55px;
      top: 15px;
      left: 15px;
    }

    span:not(.read-more) {
      max-height: 100%;
      display: block;
      color: $color-red-1;
      font-size: 21px;
      font-weight: 600;
      overflow: hidden;

      @include breakpoint ($desk) {
        font-size: 30px;
        font-weight: bold;
      }

    }

    .read-more {
      color: $color-red-1;
      font-size: 17px;
      text-transform: uppercase;
      position: absolute;
      bottom: 18px;
      right: 26px;

      @include breakpoint ($desk) {
        font-size: 21px;
        bottom: 24px;
      }

      &:after {
        content: '';
        width: 14px;
        height: 15px;
        display: inline-block;
        background: url(image-url("icons/arrow-red-fine.svg")) no-repeat center;
        background-size: contain;
        margin-left: 5px;
        position: relative;
        top: 2px;

        @include breakpoint ($desk) {
          width: 23px;
          height: 20px;
          margin-left: 7px;
        }

      }

      &:hover, &:focus {
        outline: none;
        opacity: .8;
      }

    }
  }

}
