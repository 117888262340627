.node-webpack_product-form {

  > div {

    > div {
      margin-bottom: 2.5em;

      &:last-child {
        margin-bottom: 0;
      }

      .form-item {
        margin: 0;
      }

    }

  }

  .form-item .form-item {
    margin: 0;
  }

  .form-item:not(.form-type-checkbox) > label {
    font-size: 19px;
    margin-bottom: 9px;
  }

  .form-type-managed-file input {
    font-size: 14px;
    padding: 5px;
  }

  .field-type-taxonomy-term-reference {

    .form-item {
      margin-bottom: 8px;
    }

  }

}
