.page-catalog {

  &:not(.logged-in) {

    .region-container-pre-content .col {

      @include breakpoint ($desk) {
        padding-left: 0;
      }

    }

    .region-container-content .col {

      @include breakpoint ($desk) {
        padding-left: 0;
        padding-right: 0;
      }

    }

  }

  .region-container-content .col {

    > .region-sidebar-left {

      @include breakpoint ($desk) {
        padding-left: 0;
        padding-right: 15px;
      }

    }

    > .region-content {

      @include breakpoint ($desk) {
        padding-left: 47px;
        padding-right: 15px;
      }

    }
  }

}
