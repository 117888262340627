// Ckeditor custom styles
blockquote {
  margin: .5em 20px;

  @include breakpoint ($desk) {
    padding-left: 4.4%;
    padding-right: 4.4%;
    margin: 1em 40px;
  }

  &.qLeft {

    @include breakpoint ($tab) {
      max-width: 395px;
      float: left;
    }

    @include breakpoint ($desk) {
      padding-left: 50px;
    }

  }

  &.qCenter {

    @include breakpoint ($desk) {
      padding-left: 4.4%;
      padding-right: 4.4%;
    }

  }

  &.qRight {

    @include breakpoint ($tab) {
      max-width: 395px;
      float: right;

      @include breakpoint ($desk) {
        padding-right: 50px;
      }

    }

  }

  p {
    font-size: 18px !important;
    font-family: $font-exo2;
    font-weight: 600 !important;
    font-style: italic !important;
    line-height: 1.2 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;

    @include breakpoint ($desk) {
      font-size: 30px !important;
      line-height: 34px !important;
    }

  }

}
a {

  &.linkStyle {
    display: inline-block;
    color: $color-red-1;
    font-size: 14px;
    font-family: $font-fira-sanz-condensed;
    position: relative;
    padding-right: 16px;

    @include breakpoint ($desk) {
      font-size: 20px;
      padding-right: 20px;
    }

    &:hover {
      opacity: .7;
    }

    &:after {
      content: "";
      display: inline-block;
      width: 12px;
      height: 15px;
      background: url(image-url("icons/arrow-red-medium.svg")) no-repeat center;
      background-size: contain;
      transform: translate(0, -50%);
      position: absolute;
      top: 50%;
      right: 0;

      @include breakpoint ($desk) {
      }

    }

  }

  &.downloadStyle {
    display: inline-block;
    color: $color-red-1;
    font-size: 14px;
    font-family: $font-fira-sanz-condensed;
    position: relative;
    padding-right: 15px;

    @include breakpoint ($desk) {
      font-size: 20px;
      padding-right: 25px;
    }

    &:hover {
      opacity: .7;
    }

    &:after {
      content: "";
      display: inline-block;
      width: 11px;
      height: 12px;
      background: url(image-url("icons/upload-arrow-red-bold.svg")) no-repeat center;
      background-size: contain;
      transform: translate(0, -50%) rotate(180deg);
      position: absolute;
      top: 50%;
      right: 0;

      @include breakpoint ($desk) {
        width: 14px;
        height: 16px;
      }

    }

  }

  &.downloadStyle2 {
    display: inline-block;
    color: white;
    font-size: 16px;
    font-family: $font-fira-sanz-condensed;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    position: relative;
    background: red;
    padding: 11px 21px 12px 40px;

    @include breakpoint ($desk) {
      padding: 16px 37px 16px 56px;
    }

    &:hover {
      color: white;
      opacity: .7;
    }

    &:before {
      content: "";
      display: inline-block;
      width: 15px;
      height: 19px;
      background: url(image-url("icons/arrow-white.svg")) no-repeat center;
      background-size: contain;
      transform: translate(0, -50%) rotate(90deg);
      position: absolute;
      top: 50%;
      left: 20px;

      @include breakpoint ($desk) {
        left: 33px;
      }

    }

  }

  &.redButton {
    display: inline-block;
    color: white;
    font-weight: 500;
    font-size: 16px;
    background: $color-red-1;
    border-radius: 3px;
    padding: 7px 23px 8px;
    margin-top: 6px;

    &:hover {
      color: white;
      opacity: .8;
    }

  }

  &.whiteButton {
    display: inline-block;
    color: $color-red-1;
    font-weight: 500;
    font-size: 16px;
    background: white;
    border-radius: 3px;
    padding: 7px 23px 8px;
    margin-top: 6px;

    &:hover {
      opacity: .8;
    }

  }

}
// Text indent
.rteindent1,
.rteindent2,
.rteindent3,
.rteindent4 {
  margin-left: inherit;
}
.rteindent1 {
  padding-left: 40px;
}
.rteindent2 {
  padding-left: 80px;
}
.rteindent3 {
  padding-left: 120px;
}
.rteindent4 {
  padding-left: 160px;
}
