div[id^="block-menu-menu-footer-menu"] {
  margin-bottom: 3px;

  @include breakpoint ($tab) {
    margin-bottom: 10px;
  }

  @include breakpoint ($desk) {
    margin-bottom: 0;
  }

  ul {
    text-align: center;
    padding: 0;
    margin: 0;

    @include breakpoint ($desk) {
      text-align: left;
    }

    li {
      display: inline-block;
      list-style: none;
      padding: 0;
      margin: 0;

      @include breakpoint ($desk) {
        display: block;
        margin-bottom: 5px;
      }

      &:last-child a {
        border-right: none;
        padding-right: 0;
      }

    }

  }

  a {
    display: inline-block;
    color: white;
    font-size: 11px;
    font-weight: 700;
    font-family: $font-fira-sanz-condensed;
    line-height: 1;
    text-transform: uppercase;
    border-right: 1px solid white;
    padding-right: 4px;

    @include breakpoint ($tab) {
      font-size: 16px;
      font-weight: 400;
      text-transform: initial;
      padding-right: 10px;
      padding-left: 5px;
    }

    @include breakpoint ($desk) {
      border-right: none;
      padding-left: 0;
      padding-right: 0;
    }

  }

}
