.view-books {

  .view-content {

    @include breakpoint ($desk-lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
    }

  }

  .views-row {
    border-bottom: 1px solid #4D4D4D;
    padding-bottom: 26px;
    margin-bottom: 22px;

    @include breakpoint ($desk-lg) {
      width: 33.3333%;
      text-align: center;
      padding-bottom: 29px;
      margin-bottom: 31px;
    }

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }

  }

}
