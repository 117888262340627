@mixin checkbox {
  position: relative;

  &.form-checkbox--error {
    border: 2px solid red;
  }

  input[type="checkbox"]:not(old):not(.vbo-select):not([id*="edit-comments-"]):not(.vbo-table-select-all) {
    cursor: pointer;
    opacity: 0;
    margin: 0;
    position: absolute;
    top: 4px;
    left: 0;

    + label::-moz-selection,
    + label::selection {
      color: inherit;
      background-color: transparent;
    }

    + label {
      font-size: 15px;
      line-height: 1;
      font-family: $font-fira-sanz-condensed;
      cursor: pointer;

      &:before {
        content: '';
        width: 16px;
        height: 16px;
        border: 2px solid $color-red-1;
        cursor: pointer;
        vertical-align: top;
        display: inline-block;
        margin-right: 7px;
        position: relative;
        top: -1px;

        @include breakpoint ($desk) {
          top: 2px;
        }

      }

      a {
        font-size: 15px;
        font-weight: 400;
        line-height: 1;
        text-decoration: underline;

        &:hover {
          color: $color-red-1;
        }

      }
    }

  }

  // Estilos del input seleccionado
  input[type="checkbox"]:not(old):not(.vbo-select):not(.vbo-table-select-all):checked + label:before {
    background: $color-red-1;
  }

}
