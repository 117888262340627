#views-exposed-form-general-search-page {

  .views-exposed-widgets {
    position: relative;
    margin: 0 0 10px;

    @include breakpoint ($desk) {
      margin: 0;
    }

    > div {
      float: none;
      padding: 0;

      input[type="text"] {
        color: $color-grey-1;
        background: #F2F2F2;
        border-bottom: none;
        padding: 14px 59px 9px 11px;

        @include breakpoint ($desk) {
          padding: 11px 59px 8px 15px;
        }

        @include placeholder {
          color: $color-grey-1;
          font-size: 18px;
          font-family: $font-exo2;
          font-weight: 600;

          @include breakpoint ($desk) {
            font-size: 22px;
            font-weight: bold;
          }

        }

      }

      input[type="submit"] {
        width: 44px;
        height: 100%;
        background: url(image-url("icons/lens.svg")) no-repeat center;
        background-size: 15px;
        border: none;
        outline: none;
        text-indent: -10000em;
        cursor: pointer;
        transition: all 200ms;
        padding: 10px;
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;

        &:hover {
          background-size: 20px;
        }

      }
    }
  }

}
