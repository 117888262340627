.action-links {
  padding: 0;
  margin: 0 0 15px;

  li {
    list-style: none;
    display: inline-block;
    margin-right: 3px;

    &:last-child {
      margin-right: 0;
    }

    a {
      display: block;
      color: white;
      background: $color-red-1;
      padding: 10px 15px;

      &:hover {
        background: $color-red-2;
      }

    }

  }

}
