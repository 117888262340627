.node--blog-authors--full {
  padding: 40px 0;

  > h2 {
    margin-top: 0;
    text-align: center;

    @include breakpoint ($tab) {
      padding-left: 27%;
      text-align: left;
    }

    @include breakpoint ($desk) {
      padding-left: 24%;
    }

  }

  .content {
    @include clearfix;

    .field-name-field-blog-author-picture {
      margin-bottom: 15px;
      text-align: center;

      @include breakpoint ($tab) {
        text-align: left;
        width: 27%;
        float: left;
        padding-left: 6%;
        padding-right: 2%;
        margin-bottom: 0;
      }

      @include breakpoint ($desk) {
        width: 24%;
        padding-left: 8%;
        padding-right: 0;
      }

      img {
        max-width: 135px;
        min-height: 135px;
        border-radius: 50%;
      }

    }

    .group-info-text {
      text-align: center;
      margin-bottom: 50px;

      @include breakpoint ($tab) {
        text-align: left;
        width: 73%;
        float: left;
        padding-right: 6%;
        margin-bottom: 60px;
      }

      @include breakpoint ($desk) {
        width: 76%;
        padding-right: 8%;
      }

      .field-name-field-blog-author-bio {
        margin-bottom: 35px;

        p {
          font-size: 20px;
          font-family: $font-exo2;
          line-height: 1.8;
        }

      }

    }

    .group-social-links {
      display: table;

      > div:last-child a {
        margin-right: 0;
      }

      .field-name-field-blog-author-facebook,
      .field-name-field-blog-author-twitter,
      .field-name-field-blog-author-linkedin {
        display: table-cell;
        vertical-align: middle;

        .field-label {
          display: none;
        }

        a {
          display: inline-block;
          width: 25px;
          height: 23px;
          text-indent: -10000em;
          margin-right: 20px;

          &:hover {
            opacity: .8;
          }

        }

      }

      .field-name-field-blog-author-facebook {

        a {
          background: url(image-url("social/icon-facebook.png")) no-repeat center;
          background-size: contain;
        }

      }

      .field-name-field-blog-author-twitter {

        a {
          background: url(image-url("social/icon-twitter.png")) no-repeat center;
          background-size: 23px;
        }

      }

      .field-name-field-blog-author-linkedin {

        a {
          background: url(image-url("social/icon-linkedin.png")) no-repeat center;
          background-size: contain;
        }

      }

    }

    .group-author-blocks {
      @include breakpoint ($tab) {
        padding-left: 27%;
      }

      @include breakpoint ($desk) {
        padding-left: 24%;
      }

      .field-group-format-wrapper {
        > .item-list {
          clear: both;
          margin-bottom: 40px;

          h3 {
            margin: 0 0 10px;
          }

          a {

            &:hover {
              color: $color-red-1;
            }

          }
        }
      }
    }

  }

}
