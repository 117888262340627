.node--material-display--teaser {
  color: #4D4D4D;

  > h2 {
    margin: 0;
  }

  .commerce-product-field-field-sm-ecat-portada {
    text-align: center;
    margin-bottom: 16px;

    @include breakpoint ($desk-lg) {
      margin-bottom: 17px;
    }

    img {
      max-width: 140px;
      vertical-align: top;
    }

  }

  .commerce-product-extra-field-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 15px;
    padding: 0 19%;
    margin-bottom: 3px;

    .commerce-product-title-label {
      display: none;
    }

  }

  .field-name-field-sm-ecat-autores {
    padding: 0 19%;
    margin-bottom: 5px;

    @include breakpoint ($desk-lg) {
      margin-bottom: 4px;
    }

    a {
      font-size: 14px;
      font-weight: 400;
    }

  }

  .commerce-product-field-commerce-price {
    font-size: 14px;
    font-weight: 700;
    padding: 0 19%;
  }

}
