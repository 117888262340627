.paragraphs-item-slider-style-1 {
  position: relative;
  margin-left: -15px;
  margin-right: -15px;

  @include breakpoint($tab) {
    margin-left: 0;
    margin-right: 0;
  }

  // Component style
  .paragraphs-item-slider-style-1-slides {
    position: relative;

    .field-name-field-slider-style-1-slide-img {

      img {
        width: 100%;
        height: auto;
      }

    }

    > .content {
      max-width: 85%;
      max-height: 100%;
      background: $color-red-2;
      padding-bottom: 44px;
      position: absolute;
      bottom: 0;
      right: 0;

      @include breakpoint ($desk) {
        max-width: 40%;
        padding-bottom: 65px;
      }

      @include breakpoint ($desk-lg) {
        padding-bottom: 76px;
      }

      .field-name-field-slider-style-1-slide-cont {
        overflow: hidden;

        > div {
          color: white;
          overflow-y: auto;
          padding: 26px 40px 0 19px;

          @include breakpoint ($desk) {
            padding: 26px 29px 0 38px;
          }

          @include breakpoint ($desk-lg) {
            padding: 29px 29px 0 43px;
          }

        }

      }

      h2 {
        line-height: 1;
        margin-top: 0;
        margin-bottom: 5px;

        @include breakpoint ($desk) {
          margin-bottom: 16px;
        }

        @include breakpoint ($desk-lg) {
          margin-bottom: 19px;
        }

      }

      p {
        margin: 0;
      }

    }

  }

  // Controls
  .slider-nav-wrapper {
    width: 85%;
    padding-top: 4px;
    position: absolute;
    bottom: 12px;
    right: 0;

    @include breakpoint ($desk) {
      width: 40%;
      bottom: 0;
    }

    .slider-nav {
      float: right;

      @include breakpoint ($desk) {
        padding-right: 38px;
      }

      button {
        width: 27px;
        height: 27px;
        text-indent: -10000em;
        background-color: white;
        border: none;
        border-bottom: 1px solid #ffe4e4;
        cursor: pointer;
        position: relative;
        transition: all 250ms;
        outline: none;
        margin-right: 2px;

        @include breakpoint ($desk) {
          width: 49px;
          height: 49px;
        }

        &:hover {
          opacity: .8;
          outline: none;
        }

        &:last-child {
          margin-right: 0;
        }

        &.slick-prev:before {
          transform: rotate(180deg);
        }

        &:before {
          content: "";
          display: inline-block;
          width: 100%;
          height: 100%;
          background-image: url(image-url("icons/chevron-red-fine.svg"));
          background-size: 7px;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          top: 0;
          left: 0;

          @include breakpoint ($desk) {
            background-size: 13px;
          }

        }
      }

    }

    .slick-dots {
      float: left;
      padding-top: 4px;
      padding-left: 20px;

      @include breakpoint ($desk) {
        padding-top: 11px;
        padding-left: 40px;
      }

      @include breakpoint ($desk-lg) {
        padding-top: 13px;
      }

    }
  }

}
