.layout-componer-container:not(.closed) {

  .paragraphs-item-generic-content {
    padding: 30px 10%;
  }

  .node-news.ct-as-landing-page-component:after {
    content: none;
  }

  .paragraphs-item-slider-style-5,
  .paragraphs-item-slider-style-6 {

    .field-name-field-field-slider-style-5-title,
    .field-name-field-slider-style-6-title {
      margin-bottom: 30px;
    }

    .field-name-field-slider-style-5-slides,
    .field-name-field-slider-style-6-slides {
      padding-top: 60px;
    }

  }

  .field-name-field-slider-style-7-slides {
    padding-top: 30px;
    padding-bottom: 30px;

    .slider-nav-wrapper {
      top: 65px;
    }

    .paragraphs-item-slider-style-7-slides .content {
      padding-top: 98px;
    }

  }

  .paragraphs-item-slider-style-8 {
    padding: 30px 0;

    .field-name-field-slider-style-8-text {
      margin-bottom: 40px;
    }

    .field-name-field-slider-style-8-cta {
      margin-top: 40px;
    }

  }

}
