// Color's
////////////////

// Grey
$color-grey-1: #4D4D4D;
$color-grey-1-5: #707070;
$color-grey-1-5: #737373;
$color-grey-2: #A6A6A6;
$color-grey-3: #CDCBCD;
$color-grey-4: #F3F2F3;
$color-grey-5: #F4F4F4;

// Red
$color-red-1: #FF4C4C;
$color-red-2: rgba(255, 76, 76, .8);
$color-red-3: #EE0000;
$color-red-4: #FF4C4C;
// Purple
$color-purple-1: #C78EBF;

// Yellow
$color-yellow-1: #FFD530;

// Green
$color-green-1: #61B732;
$color-green-2: #C7DC69;

// Blue
$color-blue-1: #354D5F;
$color-blue-2: #6DCEF5;
$color-blue-3: #59C4C6;

// Some helpers injected with preprocess functions and field_color:
// @todo is this the right place for this?
// bg colors.
// --------------
.bg-color-4D4D4D {
  background: $color-grey-1 !important;
}

.bg-color-A6A6A6 {
  background: $color-grey-2 !important;
}

.bg-color-CDCBCD {
  background: $color-grey-3 !important;
}

.bg-color-F3F2F3 {
  background: $color-grey-4 !important;
}

.bg-color-F4F4F4 {
  background: $color-grey-5 !important;
}

.bg-color-FF4C4C {
  background: $color-red-1 !important;
}

.bg-color-EE0000 {
  background: $color-red-3 !important;
}

.bg-color-61B732 {
  background: $color-green-1 !important;
}

.bg-color-C7DC69 {
  background: $color-green-2 !important;
}

.bg-color-354D5F {
  background: $color-blue-1 !important;
}

.bg-color-6DCEF5 {
  background: $color-blue-2 !important;
}

.bg-color-59C4C6 {
  background: $color-blue-3 !important;
}

.bg-color-C78EBF {
  background: $color-purple-1 !important;
}

.bg-color-FFD530 {
  background: $color-yellow-1 !important;
}

.bg-color-FFFFFF {
  background: white !important;
}

.bg-color-000000 {
  background: black !important;
}

// Text colors.
// --------------
.text-color-4D4D4D {
  color: $color-grey-1 !important;
}

.text-color-A6A6A6 {
  color: $color-grey-2 !important;
}

.text-color-CDCBCD {
  color: $color-grey-3 !important;
}

.text-color-F3F2F3 {
  color: $color-grey-4 !important;
}

.text-color-F4F4F4 {
  color: $color-grey-5 !important;
}

.text-color-EE0000 {
  color: $color-red-3 !important;
}

.text-color-FF4C4C {
  color: $color-red-1 !important;
}

.text-color-61B732 {
  color: $color-green-1 !important;
}

.text-color-C7DC69 {
  color: $color-green-2 !important;
}

.text-color-354D5F {
  color: $color-blue-1 !important;
}

.text-color-6DCEF5 {
  color: $color-blue-2 !important;
}

.text-color-59C4C6 {
  color: $color-blue-3 !important;
}

.text-color-C78EBF {
  color: $color-purple-1 !important;
}

.text-color-FFD530 {
  color: $color-yellow-1 !important;
}

.text-color-FFFFFF {
  color: white !important;
}

.text-color-000000 {
  color: black !important;
}
