.videoEmbed {

  iframe[align="left"] {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  iframe[align="right"] {
    margin-left: 20px;
    margin-bottom: 20px;
  }

}
