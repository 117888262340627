#edit-field-paragraph-contents-und-0-field-slider-style-4-slides-und-0-field-slide-4-see-more-link-styl-und {

  > * {
    display: inline-block;
    text-align: center;
    margin-right: 20px;
  }


  .form-type-radio {

    [type="radio"] {

      & + label {
        display: block;
      }

      &[value="button_red"] {
        background: red; // poner ejemplo
      }

      &[value="button_white"] {
        background: red; // poner ejemplo
      }

      &[value="arrow_text_white"] {
        background: red; // poner ejemplo
      }

      &[value="arrow_text_red"] {
        background: red; // poner ejemplo
      }
    }

  }


}