.view-blog {

  &.view-display-id-page {

    .views-row {
      list-style: none;
      border-bottom: 1px solid #D2D2D2;
      padding-top: 13px;
      padding-bottom: 13px;

      @include breakpoint ($tab) {
        padding-top: 22px;
        padding-bottom: 24px;
      }

      &:first-child {
        padding-top: 0;

        @include breakpoint ($tab) {
          padding-top: 22px;
        }

      }

      &:last-child {
        border-bottom: none;
      }

    }
  }

}
