.node-landing_page-form {

  > div {

    > div:not(.form-actions):not(.vertical-tabs) {
      margin: 0 0 2.5em;

      &.form-item-title {
        margin: 1.5em 0 2.5em;
      }

    }

  }

  .filter-wrapper {
    padding: 0;
  }

  .field-type-paragraphs {

    td {
      padding-top: 10px;
      padding-bottom: 10px;

      &.field-multiple-drag {

        + td {
          padding-right: 15px;
        }

      }

      > div {

        > fieldset,
        > div {
          margin-bottom: 1em;

          &:last-child {
            margin-bottom: 0;
          }

          .fieldset-wrapper {

            > div {
              margin-bottom: 1em;

              &:last-child {
                margin-bottom: 0;
              }

            }
          }
        }
      }
    }

  }

  .field-type-color-field-rgb {

    label {
      margin-bottom: 10px;
    }

    .description {
      margin-top: 10px;
    }

  }

  .form-type-managed-file {

    > label {
      margin-bottom: 7px;
    }

    .file-widget,
    .image-widget {
      margin-bottom: 5px;

      input {
        font-size: 14px;
        padding: 5px;
      }

    }

  }

  .form-submit {
    font-size: 14px;
    padding: 5px;
  }

  #edit-comment {

    > div {
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }

    }

  }

  .vertical-tabs {

    .form-type-select label {
      margin-bottom: 7px;
    }

    select {
      font-size: 14px;
      padding: 5px;
    }

  }

  .form-type-select {
    display: block !important;

    .selectize-control {

      &.error {
        outline: 1px solid red;
      }

      .error {
        background: white;
      }

      &.single > .selectize-input {
        width: 100%;
        background: white;
        padding: 8px;

        &:after {
          left: calc(100% - 20px);
        }

      }
    }

  }

  .group-see-more-link {

    .form-type-radios {

      > label {
        display: inline-block;
        margin-bottom: 8px;
      }

      .form-radios {

        @include breakpoint ($tab) {
          display: flex;
          align-items: center;
        }

      }

      .form-item {
        padding-right: 20px;
        margin-bottom: 15px;
        position: relative;

        @include breakpoint ($tab) {
          min-width: 163px;
        }

      }

      input[type="radio"] {
        width: 100%;
        height: 100%;
        background: red;
        cursor: pointer;
        opacity: 0;
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;

        &:checked + label {
          opacity: 1;
        }

        &[value="button_red"] + label {
          background: url(image-url("samples/button-001.jpg")) no-repeat top center #bfcdce;
          background-size: contain;
        }

        &[value="button_white"] + label {
          background: url(image-url("samples/button-002.jpg")) no-repeat top center #bfcdce;
          background-size: contain;
        }

        &[value="arrow_text_white"] + label {
          background: url(image-url("samples/button-003.jpg")) no-repeat top center #bfcdce;
          background-size: contain;
        }

        &[value="arrow_text_red"] + label {
          background: url(image-url("samples/button-004.jpg")) no-repeat top center #bfcdce;
          background-size: contain;
        }

        + label {
          display: block;
          text-align: center;
          opacity: .6;
          cursor: pointer;
          padding: 40px 10px 10px;
        }

      }
    }

  }

  .field-multiple-table {

    td td > div {
      margin-bottom: 1em;
    }

  }

  .field-name-field-show-pagination-dots {
    margin-top: 1em;
  }

  fieldset {
    margin: 1em 0;
  }

}
