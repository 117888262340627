#block-sm-custom-blocks-main-sm-menu-contents {
  position: fixed;
  width: 100%;
  height: 100%;
  transition: background 1s;
  background: rgba(0, 0, 0, 0);

  // This class only exist while transitioning
  &.closing {
    z-index: 3;
  }

  &.closed {
    z-index: -1;
  }

  &.open {
    background: rgba(71, 71, 71, 0.8);
    z-index: 3;

    .inner-content {
      transform: translateX(0);
    }

    + div .menu-links {
      position: fixed;
      top: 0;
      right: 0;

      @include breakpoint ($tab) {
        right: 15px;
      }

      @include breakpoint ($desk) {
        position: relative;
        right: 0;
      }

    }

  }

  &[data-content-to-show="search"] {

    .inner-content {

      .search-content {
        display: block;
      }

    }

  }

  &[data-content-to-show="language"] {

    .inner-content {

      .language-content {
        display: block;
      }

    }

  }

  &[data-content-to-show="menu"] {
    $border-color: #E3E3E3;

    .inner-content {

      .menu-content {
        display: block;
        max-height: 100%;
        overflow-y: auto;

        // Gral styles.
        ul {
          padding: 0;
          margin: 0;

          li {
            list-style: none;
            padding: 0;
            margin: 0;
            position: relative;

            &.expanded > .parent-link {
              width: 100%;
              display: inline-block;
            }

            &:not(.expanded) {
              width: 100%;
              display: flex;
              align-items: center;
            }

            &.active {

              > span > a {

                &.expand-button {
                  transform: rotate(180deg);
                }

              }

            }

            &:not(.expanded),
            > .parent-link {
              border-bottom: 1px solid $border-color;
            }

            > span > a.expand-button {
              display: inline-block;
              width: 44px;
              height: 100%;
              background: url(image-url("icons/chevron-red-medium.svg")) no-repeat center;
              background-size: 20px;
              transition: rotate ease-in-out 250ms;
              position: absolute;
              top: 0;
              right: 0;

              &:hover {
                background: url(image-url("icons/chevron-white-medium.svg")) no-repeat center $color-red-1;
                background-size: 20px;
              }

              span {
                display: none;
              }

            }

            a.active {
              color: $color-red-1 !important;
              font-weight: 500;
            }

            .parent-link {
              padding-right: 44px;
              position: relative;

              &:before {
                content: "";
                display: inline-block;
                width: 1px;
                height: 100%;
                background: $border-color;
                position: absolute;
                top: 0;
                right: 44px;
              }

            }

          }

          span,
          a:not(.expand-button) {
            font-family: $font-exo2;
            text-transform: uppercase;
          }

          a:not(.expand-button):hover {
            color: $color-red-1;
          }

          .parent-link {
            position: relative;
          }

          > span,
          .parent-link span,
          a:not(.expand-button) {
            display: block;
            width: 100%;
          }

        }

        // Menu first level
        > ul {
          border-top: 1px solid $border-color;
          overflow: hidden;

          > li {

            > a,
            > span,
            > span > a:first-child {
              color: black;
              font-size: 20px;
              font-weight: 400;
            }

            > span > span,
            > span:not(.parent-link),
            > a,
            > span a:not(.expand-button) {
              padding: 10px 5px 10px 20px;
            }

            // Menu second level
            > ul {
              display: none;

              &.open {
                display: block;
              }

              > li {

                &:not(.expanded) a {
                  padding: 12px 5px 13px 40px;
                }

                &.expanded {

                  > span > span,
                  > span:not(.parent-link),
                  > a,
                  > span a:not(.expand-button) {
                    padding: 12px 5px 13px 40px;
                  }

                }

                > a,
                > span,
                > span > a:first-child {
                  color: black;
                  font-size: 16px;
                  font-weight: 300;
                }

                // Menu third level
                > ul {
                  display: none;

                  &.open {
                    display: block;
                  }

                  > li {

                    > span,
                    a {
                      color: #666;
                      font-size: 14px;
                      font-weight: 300;
                      padding: 13px 5px 14px 70px;
                    }

                  }
                }
              }
            }
          }

        }

        > ul.language-switcher-locale-url {
          @include clearfix;
          position: absolute;
          left: 42px;
          bottom: 28px;

          @include breakpoint ($desk) {
            left: 46px;
            bottom: 43px;
          }

          > li {
            display: inline-block;
            float: left;
            position: relative;
            list-style: none;
            padding-right: 4px;
            padding-left: 5px;

            @include breakpoint ($desk) {
              padding-right: 9px;
            }

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              padding-right: 0;

              &:after {
                content: none;
              }

            }

            &.es {

              &:before {
                content: "ES";
              }

            }

            &.en {

              &:before {
                content: "EN";
              }

            }

            &.pt-br {

              &:before {
                content: "PT";
              }

            }

            &.active {

              &:before {
                color: $color-grey-1;
                font-weight: 600;
              }

            }

            &:before {
              display: inline-block;
              color: #c8c8c8;
              font-size: 16px;
              font-weight: 400;

              @include breakpoint ($desk) {
                font-size: 22px;
              }

            }

            &:after {
              content: "";
              display: inline-block;
              width: 1px;
              height: 12px;
              background: #c8c8c8;
              position: absolute;
              right: 0;
              bottom: 2px;

              @include breakpoint ($desk) {
                width: 2px;
                height: 16px;
                bottom: 3px;
              }

            }

            a {
              width: 100%;
              height: 100%;
              display: inline-block;
              text-indent: -10000em;
              position: absolute;
              top: 0;
              left: 0;
            }

            span {
              display: inline-block;
              width: 100%;
              height: 100%;
              background: rgba(255, 255, 255, 0.6);
              text-indent: -10000em;
              position: absolute;
              top: 0;
              left: 0;
            }

          }
        }
      }
    }

  }

  // If we want the menu contexts get displayed widely just comment these lines.
  > .content {
    max-width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    margin: 0 auto;

    @include breakpoint ($desk-lg) {
      max-width: 1170px;
    }

  }

  .inner-content {
    width: 100%;
    max-width: 391px;
    height: 100%;
    background: white;
    transform: translateX(100%);
    transition: transform 1s;
    z-index: 0;
    padding-top: 88px;
    padding-bottom: 60px;
    position: absolute;
    right: 0;

    @include breakpoint ($tab) {
      padding-top: 120px;
      padding-bottom: 75px;
    }

    > * {
      display: none;
    }

  }

  // Blocks
  .header-logo {
    position: absolute;
    top: 15px;
    left: 13px;

    @include breakpoint ($desk) {
      top: 23px;
      left: 16px;
    }

    img {
      width: 100%;
      height: auto;
      max-width: 120px;
      vertical-align: top;

      @include breakpoint ($desk) {
        width: auto;
      }

    }
  }

}
