.node-webpack-project.node-teaser {
  text-align: center;
  padding: 0 10px 20px;

  .link-wrapper {
    display: inline-block;

    > h4 {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
    }

  }

  .field-name-field-webpack-project-img-front {
    margin-bottom: 10px;

    img {
      vertical-align: top;
    }

  }

}
