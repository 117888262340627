.page-webpack {

  #block-system-main > .content {

    > .map-title {
      @extend h1;
      text-align: center;
      margin: 1.1em 0;
    }

    > h2:not(.map-title) {
      color: $color-red-3;
      font-size: 26px;
      text-align: center;
      text-transform: uppercase;
      margin: 1em 0 1.5em;

      @include breakpoint ($desk) {
        margin: 1.6em 0 3em;
      }

    }

    .webpack-message-info {
      padding: 20px;
      border: 2px solid #dab62b;
      background: $color-yellow-1;
      text-align: center;
      margin: 30px 0;
    }

    > .subject-title {
      color: $color-red-1;
      margin: 0 0 20px 0;
    }

    > .item-list {

      @include breakpoint ($tab) {
        display: block;
      }

      &:last-of-type {
        border-bottom: none;
        margin-bottom: 40px;
      }

      > ul {
        display: block;
        padding: 0;
        margin: 0;

        @include breakpoint ($tab) {
          display: flex;
        }

        > li {
          width: 100%;
          list-style: none;
          padding: 0;
          margin: 0;

          @include breakpoint ($tab) {
            width: 25%;
          }

          .node {
            margin-bottom: 20px;

            @include breakpoint ($desk) {
              margin-bottom: 0;
            }

            h4 {
              display: none;
            }

          }
        }
      }

    }

    > .map-wrapper {
      text-align: center;

      > svg {

        @include breakpoint (0 $tab - 1px) {
          width: 100% !important;
          height: auto !important;
        }

        @include breakpoint ($tab) {
          width: 650px !important;
        }

      }
    }

    > .back-link {
      color: $color-red-1;
      font-size: 18px;
      font-weight: 400;
      text-transform: none;
      background-color: transparent;
      border: 3px solid $color-red-1;
      cursor: pointer;
      transition: all 250ms;
      padding: 4px 29px 2px;
      position: relative;

      &:hover {
        color: white;
        background-color: $color-red-1;
      }

      &:before {
        content: '<';
        display: inline-block;
        font-size: 28px;
        font-weight: 300;
        line-height: 18px;
        margin-right: 7px;
        position: relative;
        top: 3px;
      }

    }
  }

}
