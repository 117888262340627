.paragraphs-item-slider-style-7 {

  .field-name-field-slider-style-7-slides {
    position: relative;
    padding-top: 30px;

    @include breakpoint ($desk) {
      padding-top: 155px;
    }

    .slick-slide {
      outline: none;
      padding-bottom: 30px;

      @include breakpoint ($desk) {
        padding-bottom: 40px;
      }

    }

    .paragraphs-item-slider-style-7-slides {

      > div {

        @include breakpoint ($tab) {
          width: 50%;
          float: left;
        }

      }

      .field-name-field-slider-style-7-slide-img {
        padding-right: 20px;

        img {
          width: 100%;
          height: auto;
        }

      }

      .content {
        padding-top: 30px;
        padding-left: 15px;
        padding-right: 15px;

        @include breakpoint ($tab) {
          padding-top: 70px;
          padding-left: 20px;
          padding-right: 15px;
        }

        @include breakpoint ($desk) {
          padding-top: 130px;
        }

        > a {
          display: inline-block;
          color: white;
          font-weight: 600;
          border-radius: 4px;
          padding: 10px 20px 11px;
          margin-top: 10px;

          @include breakpoint ($desk) {
            padding: 10px 43px 11px;
            margin-top: 23px;
          }

          &.bg-color-F3F2F3,
          &.bg-color-CDCBCD,
          &.bg-color-F4F4F4,
          &.bg-color-FFFFFF {
            color: $color-grey-1;
          }

          &:hover {
            opacity: .7;
          }

        }

      }

      h1, h2, h3, h4, h5, h6 {
        font-weight: inherit;
        margin: 0;
      }

      p {
        font-family: $font-exo2;
      }

    }

    .slider-nav-wrapper {
      transform: translate(-50%, 0);
      position: absolute;
      left: 50%;
      bottom: 0;

      ul {
        padding: 0;

        li {
          margin-right: 13px;

          &:last-child {
            margin-right: 0;
          }

        }

      }

      .slick-active button {
        background: $color-grey-1;
      }

      button {
        width: 15px;
        height: 15px;
        background: $color-grey-3;
        border-radius: 50%;
      }

    }
  }

}
