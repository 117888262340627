.share-social-links {
  line-height: 1;
  position: relative;
  z-index: 2;

  .trigger {
    display: inline-block;
    width: 25px;
    height: 25px;
    background: url(image-url("icons/share-red.png")) no-repeat center;
    background-size: 21px;
    cursor: pointer;

    &:focus,
    &:hover {

      + ul {
        display: inline-block;
      }

    }

    span {
      display: none;
    }

  }

  ul {
    display: none;
    max-width: 35px;
    //padding: 10px 0 0;
    padding: 0;
    margin: 0;
    position: absolute;
    top: calc(100% - 6px);
    left: -5px;

    &:focus,
    &:hover {
      display: inline-block;
    }

    li {
      list-style: none;
      margin: 0 0 7px;

      &:first-child {
        margin-top: 10px;
      }

      &:last-child {
        margin: 0;
      }

      a {
        display: block;
        width: 35px;
        height: 35px;
        border: 1px solid transparent;
        border-radius: 50%;
        transition: all 400ms;

        &.fb {
          background: url(image-url("social/white-icon-facebook.png")) no-repeat center $color-red-1;
          background-size: 35%;
        }

        &.tw {
          background: url(image-url("social/white-icon-twitter.png")) no-repeat center $color-red-1;
          background-size: 60%;
        }

        &.in {
          background: url(image-url("social/white-icon-linkedin.png")) no-repeat center $color-red-1;
          background-size: 54%;
        }

        &:hover {
          background-color: $color-red-3;
        }

        span {
          display: none;
        }

      }
    }
  }

}

// Hidden social menu on front pages.
.front {
  .share-social-links {
    display: none;
  }
}