.paragraphs-item-video {
  position: relative;

  a {
    display: block;
    width: 100%;
    height: 100%;

    &:before {
      content: '';
      display: inline-block;
      width: 48px;
      height: 46px;
      background: url(image-url("icons/play.svg")) no-repeat center;
      background-size: contain;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;

      @include breakpoint ($desk) {
        width: 85px;
        height: 85px;
      }

    }

    img {
      width: 100%;
      height: auto;
    }

  }

}
