.webpack-categories {

  // Generic styles.
  > h2 {
    color: $color-red-3;
    font-size: 26px;
    text-align: center;
    text-transform: uppercase;
    margin: 1em 0 1.5em;

    @include breakpoint($desk) {
      margin: 4.1em 0 2.6em;
    }
  }

  h3 {
    @extend h1;
    text-align: center;
    margin: 0 0 50px;

    @include breakpoint($desk) {
      margin: 0 0 70px;
    }
  }

  .hidden {
    display: none;
  }

  .item-list .webpack-facet {
    padding: 0;
    margin: 0;

    @include breakpoint($tab) {
      display: flex;
      justify-content: space-around;
      flex-flow: row wrap;
    }

    li {
      list-style: none;
      padding: 0;
      margin: 0 0 20px;

      @include breakpoint($tab) {
        display: flex;
        width: 30%;
      }

      @include breakpoint($desk) {
        margin: 0;
        margin-bottom: 40px;
      }

      img {
        height: 80px;
        width: auto;
        margin-bottom: 16px;
      }

      a {
        color: $color-grey-1-5;
        -webkit-box-shadow: 0 5px 5px -2px rgba(199, 199, 199, 1);
        -moz-box-shadow: 0 5px 5px -2px rgba(199, 199, 199, 1);
        box-shadow: 0 5px 5px -2px rgba(199, 199, 199, 1);
        text-align: center;
        cursor: pointer;
        transition: all 250ms;
        font-family: $font-exo2;
        margin-left: 2px;
        border: 1px solid transparent;

        @include breakpoint($tab) {
          padding-left: 10px;
          padding-right: 10px;
        }

        &:active,
        &:hover,
        &.webpack-active {
          color: $color-red-2;
          border-color: $color-red-2;
          border-radius: 3px;

          span.text {
            color: $color-red-2;
          }
        }
      }
    }
  }

  // Specific styles.
  .stage,
  .course {

    .webpack-facet {

      a {
        width: 100%;
        height: 100%;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column wrap;
        min-height: 160px;

        @include breakpoint($desk) {
          min-height: 160px;
        }

        span.number {
          color: black;
          font-weight: bold;
          font-size: 80px;
          margin-bottom: 10px;
          display: contents;
          min-height: 45px;
        }

        span.text {
          color: $color-grey-1-5;
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 0px;
        }
      }
    }
  }

  .stage {
    margin-bottom: 2.5em;

    @include breakpoint($desk) {
      margin-bottom: 3.5em;
    }

    li {

      @include breakpoint($desk) {
        width: 25%;
      }
    }

    a {
      font-size: 20px;
    }
  }

  .course {
    margin-bottom: 2.5em;

    @include breakpoint($desk) {
      margin-bottom: 3.5em;
    }

    li {

      @include breakpoint($desk) {
        width: 25%;
      }
    }

    a {
      font-size: 17px;
    }
  }

  .subject-wrapper {
    overflow: hidden;

    @include breakpoint($desk) {
      padding: 0 15px;
    }

    .webpack-facet {

      @include breakpoint($tab) {
        flex-wrap: wrap;
        justify-content: flex-start;
      }

      @include breakpoint($desk) {
        width: 100%;
        flex-wrap: wrap;
      }

      li {

        @include breakpoint($tab) {
          width: 33%;
          display: flex;
          justify-content: center;
        }

        @include breakpoint($desk) {
          margin-bottom: 54px;
        }

        a {
          background: $color-grey-5;
          color: $color-grey-1-5;
          min-height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          margin: 0 auto;

          &.webpack-active {
            background: $color-grey-1;
            border-color: $color-grey-1;
            color: white;
          }

          @include breakpoint($tab) {
            width: 80%;
            padding: 0;
          }

          @include breakpoint($desk) {
            min-height: 80px;
            margin: 0;
          }
        }
      }
    }
  }

  .cta-wrapper {
    @include clearfix;
    margin-bottom: 0.5em;
    display: flex;
    margin-top: 60px;
    justify-content: center;
    align-items: center;

    .back-link {
      float: left;
      position: relative;

      &:before {
        content: '<';
        margin-right: 7px;
      }

    }

    .next-link {
      display: none;
      color: white;
      background: $color-red-4;
      border: none;
      border-radius: 3px;
      cursor: default;

      &.active {
        display: inline-block;
        text-transform: none;
        background: $color-red-4;
        border: none;
        cursor: pointer;
      }
    }

    a {
      color: $color-red-1;
      font-size: 18px;
      font-weight: 600;
      text-transform: none;
      background-color: transparent;
      border: 3px solid $color-red-1;
      cursor: pointer;
      transition: all 250ms;
      padding: 10px 29px 12px;
      font-family: $font-exo2;

      &:hover {
      }

      &:before,
      &:after {
        display: inline-block;
        font-size: 28px;
        font-weight: 300;
        line-height: 18px;
        position: relative;
        top: 3px;
      }

    }
  }

  p.title {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 60px;
    font-size: 22px;
  }

  &.subject-lvl {
    .cta-wrapper {
      .next-link {
        text-transform: uppercase;
        display: inline-block;
        cursor: auto;
        opacity: 0.5;

        &.active {
          cursor: pointer;
          opacity: 1;
        }
      }
    }
  }
}
