.form-type-textarea,
.webform-component-textarea {
  @include textarea;
}

.form-type-mail,
.form-type-password,
.form-type-textfield,
.webform-component-textfield,
.webform-component-email {

  label {
    font-size: 16px;
    margin-bottom: 5px;

    @include breakpoint ($desk) {
      font-size: 19px;
      margin-bottom: 9px;
    }

  }

  input {
    width: 100%;
    font-size: 16px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid $color-grey-2;
    outline: none;
    padding: 0 2px 1px;

    @include breakpoint ($desk) {
      font-size: 19px;
    }

  }

}

// Aplicamos estilos a checkbox's
// solo para usuarios anonimos
body:not(.logged-in) {

  .form-type-checkbox {
    @include checkbox;
  }

}
