.view-news {

  &.view-display-id-page {

    > .view-content > .item-list {

      > ul {
        margin: 0;

        > li {
          list-style: none;
          border-bottom: 1px solid #d2d2d2;
          padding-top: 13px;
          padding-bottom: 13px;
          margin: 0;

          @include breakpoint ($tab) {
            padding-top: 22px;
            padding-bottom: 24px;
          }

          &:first-child {
            padding-top: 0;

            @include breakpoint ($tab) {
              padding-top: 22px;
            }

          }

          &:last-child {
            border-bottom: none;
          }

        }
      }
    }

  }

}
