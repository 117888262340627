.messages {

  &.error {

    .placeholder {
      word-break: break-word;
    }

  }

}
