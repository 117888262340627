.paragraphs-item-3-contents-with-icon {
  padding: 30px 20px;

  @include breakpoint ($tab) {
    padding: 50px 0 60px;
  }

  @include breakpoint ($desk) {
    padding: 100px 0 120px;
  }

  .field-name-field-3-contents-icon-title {
    margin-bottom: 50px;

    @include breakpoint ($desk) {
      margin-bottom: 110px;
    }

    h1, h2, h3, h4, h5, h6 {
      font-weight: initial;
      margin: 0;
    }

  }

  .field-name-field-3-contents-icon-contents {

    > .field-items {

      @include breakpoint ($tab) {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;
      }

      > .field-item {
        margin-bottom: 50px;

        @include breakpoint ($tab) {
          width: 33.3333%;
          margin-bottom: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

      }
    }
  }

}
