.node--article--teaser {

  .info-top {
    color: #C1C1C1;
    font-size: 12px;
    margin-bottom: 4px;

    @include breakpoint ($tab) {
      font-size: 14px;
      margin-bottom: 6px;
    }

    > div {
      display: inline-block;
    }

    .category {
      text-transform: uppercase;
    }

  }

  .read-more {
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    transition: all 200ms;

    @include breakpoint ($tab) {
      font-size: 24px;
    }

    &:hover {

      &:after {
        opacity: 1;
      }

    }

    &:after {
      content: "";
      display: inline-block;
      width: 21px;
      height: 17px;
      background: url(image-url("icons/arrow-red-bold.svg")) no-repeat center;
      background-size: contain;
      transition: all 200ms;
      opacity: 0;
      margin-left: 3px;
      position: relative;
      top: 2px;
    }

  }

  .field-name-field-article-category {

    .field-item {
      display: inline-block;
      margin-top: 10px;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

    }

    h1, h2, h3, h4, h5, h6 {
      font-size: 12px;
      line-height: normal;
      margin: 0;

      @include breakpoint ($tab) {
        font-size: 13px;
      }

    }

    a {
      display: inline-block;
      color: $color-red-1;
      font-size: 12px;
      font-weight: 500;

      @include breakpoint ($tab) {
        font-size: 13px;
      }

      &:before {
        content: '#';
        display: inline-block;
      }

    }
  }

}
