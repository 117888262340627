body:not(.cke_editable) {

  .ck-responsive-table {

    thead {
      display: none;

      @include breakpoint ($tab) {
        display: table-header-group;
      }

      tr {
        background: $color-grey-4;
      }

      th {
        font-weight: 600;
        border-bottom: 2px solid $color-grey-3;
        padding: 10px 5px;
      }

    }

    tbody {
      tr {
        border-bottom: 2px solid $color-grey-4;
        display: block;
        margin-bottom: 13px;

        @include breakpoint ($tab) {
          display: table-row;
          border-bottom: 1px solid $color-grey-4;
        }

        &:nth-child(even) {
          background: $color-grey-5;
        }

        td {
          display: block;
          text-align: right;
          padding: 10px 5px;

          @include breakpoint ($tab) {
            text-align: left;
            display: table-cell;
          }

          &:before {
            content: attr(data-label);
            font-weight: 600;
            text-transform: uppercase;
            float: left;

            @include breakpoint ($tab) {
              content: none;
            }

          }

          &:last-child {
            border-bottom: 0;
          }

        }

      }
    }

  }
}