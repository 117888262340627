.paragraphs-item-content-with-icon {

  @include breakpoint ($tab) {
    padding: 0 15px;
  }

  @include breakpoint ($desk) {
    width: 100%;
    display: table;
    padding: 0 30px;
  }

  > div {

    @include breakpoint ($desk) {
      display: table-cell;
      vertical-align: top;
    }

  }

  .field-name-field-content-with-icon-icon {
    width: 100px;
    margin-bottom: 20px;

    @include breakpoint ($desk) {
      margin-bottom: 0;
    }

    img {
      max-width: 70px;
    }

  }

  .content {

    @include breakpoint ($desk) {
      padding-top: 17px;
    }

    .field-name-field-content-with-icon-content {

      * {
        font-family: $font-exo2;
      }

      h1, h2, h3, h4, h5, h6 {
        font-weight: initial;
        margin: 0 0 11px;
      }

      p {
        font-size: 16px;
        line-height: 1.6;
        margin: 0 0 1em;

        @include breakpoint ($desk) {
          font-size: 19px;
          line-height: 1.6;
        }

      }

    }

    > a {
      display: inline-block;
      color: white;
      font-weight: 500;
      font-size: 16px;
      border-radius: 3px;
      padding: 10px 23px 11px;
      margin-top: 6px;

      &.bg-color-CDCBCD,
      &.bg-color-F4F4F4,
      &.bg-color-F3F2F3,
      &.bg-color-FFFFFF {
        color: black;
      }

      &:hover {
        opacity: .8;
      }

    }

  }

}
