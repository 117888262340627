@mixin textarea {

  label {
    font-size: 16px;
    margin-bottom: 5px;

    @include breakpoint ($desk) {
      font-size: 19px;
      margin-bottom: 9px;
    }

  }

  textarea {
    font-size: 14px;
    border: none;
    outline: none;
    background: #f9f9f9;
    padding: 7px;

    @include breakpoint ($desk) {
      font-size: 16px;
    }

  }

}
