.node--book--full {
  $color-book: #949494;

  > .content {
    margin-bottom: 50px;
  }

  .top {
    margin-bottom: 38px;

    > .back-button {
      color: $color-red-1;
      text-transform: uppercase;
      font-size: 20px;
      margin-bottom: 30px;

      @include breakpoint($desk) {
        font-size: 26px;
      }
    }

    > h2 {
      font-size: 30px;
      font-weight: 600;
      text-transform: uppercase;
      margin: 20px 0 11px;

      @include breakpoint($desk) {
        font-size: 40px;
      }

    }

    .authors {
      display: flex;
      flex-wrap: wrap;

      &.truncate {
        .field-item:nth-child(n+3) {
          display: none;
        }

        .field-item:nth-child(2) {
          &:after {
            display: block;
            content: "..."
          }
        }
      }

      .see-more-authors {
        span {
          display: none;
        }

        span.active {
          display: block;
          color: $color-red-1;
          margin-left: 4px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .field-label {
        display: none;
      }

      .field-item {
        display: flex;

        &:not(:nth-last-child(2)):after {
          display: block;
          content: ",";
          margin-right: 4px;
        }

        a {
          font-size: 21px;
          font-family: $font-exo2;
        }
      }
    }
  }

  .content-wrapper {

    @include breakpoint($desk) {
      display: flex;
    }

    .left {

      @include breakpoint($desk) {
        width: 27%;
      }

      .field-name-field-book-cover-image {
        background: white;
        box-shadow: 0 3px 9px 0 rgba(50, 50, 50, 0.2);
        border-radius: 5px;
        padding: 30px 20px;
        margin-bottom: 18px;

        .field-item {
          text-align: center;
        }

      }

      .first-chapter {
        display: inline-block;
        color: $color-red-1;
        font-size: 18px;
        font-family: $font-exo2;
        font-weight: 700;
        background: url(image-url("icons/icon-red-paper.svg")) no-repeat center left;
        background-size: 20px;
        padding-left: 30px;
        margin-left: 20px;
        margin-bottom: 33px;
      }

      .social-share {
        display: flex;
        align-items: flex-start;
        padding-left: 20px;
        margin-bottom: 30px;

        .title {
          color: $color-book;
          font-size: 16px;
          font-weight: 500;
          margin-right: 20px;
        }

        ul {
          display: flex;
          align-items: flex-end;
          padding: 0;
          margin: 0;

          li {
            list-style: none;
            margin: 0 11px 0 0;

            &:last-child {
              margin-right: 0;
            }

            a {
              color: $color-book;
              padding: 3px;

              &:hover, &:active {
                color: black;
              }

              &:before {
                transition: all .2s;
              }

              span {
                display: none;
              }

            }
          }
        }

      }

    }

    .center {

      @include breakpoint($desk) {
        width: 60%;
        padding: 0 40px;
      }

      .info {
        padding-top: 20px;
      }

      .field-name-field-book-synopsis {
        color: $color-book;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        margin-bottom: 40px;
      }

      .info-wrapper {
        display: flex;
        justify-content: space-between;

        .list-info {

          @include breakpoint($desk) {
            width: 50%;
          }

          ul {
            padding: 0;
            margin: 0 0 8px;

            li {
              list-style: none;
              border-bottom: 1px solid $color-grey-3;
              margin: 0;

              &.hidden-attribute {
                display: none;
              }
            }

            .field {
              display: flex;
              padding: 13px 0 9px;

              .field-label {
                color: $color-book;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.3;
                margin-right: 2px;
              }

              .field-item {
                // color: $color-red-1;
                font-size: 14px;
                font-weight: bold;
                line-height: 1.3;

                a {
                  color: $color-red-1;
                  font-size: 14px;
                  font-weight: bold;
                  line-height: 1.3;

                  &:hover, &:active {
                    color: $color-red-3;
                  }

                }
              }
            }

          }

          .linkStyle {
            font-size: 14px;
            font-family: $font-exo2;
            font-weight: bold;
            text-transform: uppercase;
          }

        }

        .field-name-field-book-topics {
          width: 50%;

          .field-item {
            display: inline-block;
            margin-left: 20px;
            margin-bottom: 16px;

            color: $color-book;
            font-size: 16px;
            background: $color-grey-4;
            border-radius: 15px;
            transition: all .4s;
            padding: 8px 35px 7px;

            a {
              color: $color-book;
              font-size: 16px;
              font-weight: normal;

              &:hover {
                color: $color-grey-1;
              }
            }

          }

        }
      }

    }

    .right {
      text-align: center;

      @include breakpoint($desk) {
        width: 13%;
      }

      .field-name-field-book-price-iva {
        font-size: 24px;
        font-family: $font-exo2;
        font-weight: 500;

        .field-label {
          display: none;
        }
      }

      .buy {
        background: #6fc312;
        color: white;
        font-weight: normal;
        display: inline-block;
        padding: 10px 50px;
        font-size: 14px;
        margin: 10px 0;
        border-radius: 10px;
        text-transform: uppercase;
      }

      .payment-gateway-info {
        text-align: center;
        font-size: 12px;
      }
    }

  }

  .field-name-field-book-related-titles {

    .field-label {
      font-size: 18px;
      font-family: $font-exo2;
      font-weight: 500;
      padding: 0 20px;
      margin-bottom: 10px;
    }

    .slick-slider {

      > button {
        width: 20px;
        height: 20px;
        z-index: 1;
        text-indent: -10000em;
        overflow: hidden;
        transform: translateY(-50%);
        border: none;
        outline: none;
        background: transparent;
        cursor: pointer;
        padding: 5px;
        margin-top: -4%;
        margin-bottom: 0;
        position: absolute;
        top: 50%;

        &[aria-label="Previous"] {
          left: -8px;

          @include breakpoint($desk-lg) {
            left: -24px;
          }

          &:before {
            transform: rotate(-90deg);
            background: url(image-url("icons/chevron-grey-bold.svg")) no-repeat center left;
            background-size: 20px;
          }

        }

        &[aria-label="Next"] {
          right: -8px;

          @include breakpoint($desk-lg) {
            right: -24px;
          }

          &:before {
            transform: rotate(90deg);
            background: url(image-url("icons/chevron-grey-bold.svg")) no-repeat center left;
            background-size: 20px;
          }

        }

        &:before {
          content: "";
          display: inline-block;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 0;
          left: 0;
        }

      }

      .slick-dots {
        text-align: center;
        padding: 0;
        margin: 10px 0 0;

        li {
          margin-right: 12px;

          &.slick-active button {
            background: #949494;
          }

          button {
            background: $color-grey-4;
            border-radius: 50%;
          }

        }
      }

    }

    .slick-list {
      margin: 0 20px;

      @include breakpoint($desk-lg) {
        margin: 0;
      }

      .slick-track {
        display: flex !important;

        > .slick-slide {
          height: inherit !important;
          outline: none;
          padding: 5px 5px 11px;

          .node {
            height: 100%;
            background: white;
            box-shadow: 0 3px 9px 0 rgba(50, 50, 50, 0.2);
            border-radius: 5px;
            padding: 10px;
          }

        }
      }
    }
  }

}
