.node-basic_page-form {
  padding: 30px 0;

  > div {

    > div:not(.form-actions):not(.vertical-tabs) {
      margin: 0 0 2.5em;
    }

  }

  .filter-wrapper {
    padding: 0;
  }

  #edit-comment {

    > div {
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }

    }

  }

  .vertical-tabs {

    .form-type-select label {
      margin-bottom: 7px;
    }

    select {
      font-size: 14px;
      padding: 5px;
    }

  }

}
