.specific-component-sm-world-map {
  $paddingPrimaryBox: 2%;
  margin-left: -15px;
  margin-right: -15px;

  @include breakpoint ($tab) {
    display: flex;
    margin-left: 0;
    margin-right: 0;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      margin: 0;
    }

  }

  > div {

    @include breakpoint ($tab) {
      width: 50%;
      float: left;
      flex: 1;
    }

  }

  // List side
  .list-wrapper {
    width: 100%;

    @include breakpoint ($tab) {
      padding-right: $paddingPrimaryBox;
    }

    h3 {
      color: $color-red-1;
      font-size: 24px;
      text-transform: uppercase;
      padding-left: 40px;
      margin: 0 0 14px;

      @include breakpoint ($tab) {
        padding-left: 9px;
      }

      @include breakpoint ($desk) {
        font-size: 30px;
        margin: 0 0 36px;
      }

    }

    .country-list {
      $paddingList: 30px;

      > .paragraphs-items-field-sm-w-map-items-full > .field-name-field-sm-w-map-items > .field-items > .field-item {

        @include breakpoint ($tab) {
          width: 50%;
          float: left;
          margin-bottom: 5px;
        }

        &:nth-child(odd) {

          @include breakpoint ($tab) {
            clear: left;
          }

          @include breakpoint ($desk) {
            padding-right: $paddingList;
          }

        }

        &:nth-child(even) {

          @include breakpoint ($tab) {
            clear: right;
          }

          @include breakpoint ($desk) {
            padding-left: $paddingList;
          }

        }

      }

      li {
        @include clearfix;

        &.active {
          background-color: $color-red-1;
          position: relative;

          a {
            color: white;
            font-weight: 700;

            &:after {
              content: none;
            }

          }

          > div.location {
            height: auto;
            opacity: 1;
          }

        }

        > a {
          display: block;
          font-size: 16px;
          font-weight: 400;
          font-family: $font-fira-sanz-condensed;
          text-transform: uppercase;
          padding: 5px 60px 5px 40px;
          position: relative;

          @include breakpoint ($tab) {
            font-family: $font-exo2;
            padding: 5px 10px;
          }

          @include breakpoint ($desk) {
            font-size: 20px;
            padding: 8px 18px 9px;
          }

          &:hover {
            font-weight: 700;
          }

          &:after {
            content: "";
            display: inline-block;
            width: 10px;
            height: 7px;
            background: url(image-url("icons/chevron-red-medium.svg")) no-repeat center;
            transform: rotate(-90deg);
            position: absolute;
            top: 12px;
            right: 50px;

            @include breakpoint ($desk) {
              top: 17px;
              right: 19px;
            }

          }

        }

        > div.location {
          height: 0;
          opacity: 0;
          transition: all 250ms;

          @include breakpoint ($tab) {
            display: none;
          }

          .content {
            color: white;
            padding: 3px 40px 10px;

            .close {
              display: inline-block;
              width: 10px;
              height: 7px;
              background: url(image-url("icons/chevron-white-medium.svg")) no-repeat center;
              background-size: 10px;
              transform: rotate(180deg);
              padding: 10px;
              position: absolute;
              top: 3px;
              right: 44px;
            }

            .field-name-field-sm-w-map-item-desc .field-item {
              font-size: 12px;
              font-family: $font-fira-sanz-condensed;
              text-transform: uppercase;
              margin: 0 0 7px;
              position: relative;

              &:before {
                content: "";
                display: inline-block;
                width: 5px;
                height: 5px;
                background: white;
                border-radius: 50%;
                margin-right: 4px;
                margin-bottom: 1px;
              }

            }

            .field-name-field-sm-w-map-item-link .field-item {
              margin-bottom: 3px;
              position: relative;

              &:last-child {
                margin-bottom: 0;
              }

              a {
                display: block;
                color: white;
                font-size: 12px;
                font-family: $font-fira-sanz-condensed;
                font-weight: 700;
                text-transform: uppercase;
                white-space: nowrap;
                padding-right: 29px;

                &:before {
                  content: "";
                  display: inline-block;
                  width: 5px;
                  height: 5px;
                  background: white;
                  border-radius: 50%;
                  margin-right: 4px;
                  margin-bottom: 1px;
                }

                &:after {
                  content: "";
                  display: inline-block;
                  width: 11px;
                  height: 11px;
                  background: url(image-url("icons/arrow-white.svg")) no-repeat top center;
                  background-size: contain;
                  transform: translate(0, -50%);
                  position: absolute;
                  right: 10px;
                  top: 9px;
                }

              }
            }
          }
        }
      }
    }

  }

  // Map side
  .map {
    display: none;
    background: url(image-url("icons/mapa_home.svg")) no-repeat top center;
    background-size: cover;
    position: relative;
    padding-left: $paddingPrimaryBox;

    @include breakpoint ($tab) {
      display: block;
      min-height: 200px;
    }

    @include breakpoint ($desk) {
      min-height: 250px;
    }

    .location {
      display: none;
      background: $color-red-2;
      transform: translate(-50%, -50%);
      padding: 11px 25px 17px;
      position: absolute;
      top: 50%;
      left: 50%;

      @include breakpoint ($desk) {
        padding: 0;
      }

      &.active {
        display: inline-block;
      }

      .content {
        color: white;
        position: relative;

        @include breakpoint ($desk) {
          padding: 22px 27px 33px;
        }

        .close {
          width: 14px;
          height: 14px;
          background: url(image-url("icons/white-cross-strong.svg")) no-repeat center;
          background-size: 14px;
          cursor: pointer;
          padding: 12px;
          position: absolute;
          top: 0;
          right: -4px;

          @include breakpoint ($desk) {
            background-size: contain;
            padding: 0;
            top: 30px;
            right: 30px;
          }

        }

        .field-name-field-sm-w-map-item-desc {
          font-size: 14px;
          font-weight: 700;
          line-height: 1.7;
          text-transform: uppercase;
          padding-right: 20px;
          margin: 0 0 23px;

          @include breakpoint ($desk) {
            font-size: 29px;
            line-height: 1;
            border-bottom: 1px solid white;
            padding-bottom: 12px;
            margin: 0 0 21px;
          }

        }

        .field-name-field-sm-w-map-item-link {

          .field-item {
            margin-bottom: 3px;

            @include breakpoint ($desk) {
              margin-bottom: 10px;
            }

            &:last-child {
              margin-bottom: 0;
            }

            a {
              display: inline-block;
              color: white;
              font-size: 12px;
              font-weight: 700;
              text-transform: uppercase;
              position: relative;
              white-space: nowrap;

              @include breakpoint ($desk) {
                font-size: 20px;
              }

              &:before {
                content: "";
                display: inline-block;
                width: 4px;
                height: 4px;
                background: white;
                border-radius: 50%;
                margin-right: 4px;
                margin-bottom: 1px;

                @include breakpoint ($desk) {
                  width: 7px;
                  height: 7px;
                  margin-left: 4px;
                  margin-right: 7px;
                  margin-bottom: 3px;
                }

              }

              &:after {
                content: "";
                display: inline-block;
                width: 14px;
                height: 13px;
                background: url(image-url("icons/arrow-white.svg")) no-repeat top center;
                background-size: contain;
                margin-left: 7px;
                margin-bottom: -2px;

                @include breakpoint ($desk) {
                  width: 25px;
                  height: 16px;
                  margin-left: 4px;
                  margin-bottom: 0;
                }

              }
            }
          }
        }
      }
    }
  }

}
