.paragraphs-item-slider-style-3 {
  position: relative;

  .paragraphs-item-slider-style-3-slides {
    position: relative;

    .field-name-field-slider-style-3-slide-img {

      img {
        width: 100%;
        height: auto;
      }

    }

    > .content {
      width: 50%;
      height: 100%;
      padding-top: 22px;
      padding-bottom: 110px;
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;

      .field-name-field-slider-style-3-slide-cont {
        display: none;
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 30px;

        @include breakpoint ($desk) {
          display: block;
        }

      }

      .field-name-field-slider-s3-see-more-link {
        text-align: center;
        position: absolute;
        right: 23px;
        bottom: 53px;

        @include breakpoint ($desk) {
          right: 30px;
          bottom: 64px;
        }

        a {
          display: inline-block;
          color: $color-red-1;
          font-size: 17px;
          text-transform: uppercase;
          border: 3px solid $color-red-1;
          padding: 2px 6px 4px;

          @include breakpoint ($desk) {
            font-size: 20px;
            padding: 5px 5px 4px;
          }

          &:hover {
            color: $color-red-2;
            border: 3px solid $color-red-2;
          }

        }

      }

      h2 {
        margin-top: 0;
        margin-bottom: 10px;
      }

      p {
        margin: 0;
      }

    }

  }

  // Controls
  .slider-nav-wrapper {
    width: 50%;
    padding-top: 4px;
    position: absolute;
    bottom: 8px;
    right: 13px;

    @include breakpoint ($desk) {
      bottom: 21px;
      right: 26px;
    }

    .slick-dots {
      float: right;
    }

  }

}
