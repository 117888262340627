.view-most-relevant-blocks {

  .view-content {
    @include clearfix;
  }

  .views-row {
    margin-bottom: 20px;

    @include breakpoint ($tab) {
      width: 50%;
    }

    &:nth-child(odd) {
      @include breakpoint ($tab) {
        float: left;
        clear: left;
        padding-right: 10px;
      }
    }

    &:nth-child(even) {
      @include breakpoint ($tab) {
        width: 50%;
        float: right;
        clear: right;
        padding-left: 10px;
      }
    }

  }

}
