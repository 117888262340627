#block-blockify-blockify-logo {
  width: 85px;
  padding-top: 11px;
  padding-right: 11px;

  @include breakpoint ($desk) {
    width: 140px;
    padding-top: 10px;
    padding-right: 14px;
  }

}

// Change some styles for Cruilla site.
.domain-cruilla #block-blockify-blockify-logo {
  @include breakpoint ($desk) {
    width: 140px;
  }
}
