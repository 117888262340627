.view-display-id-webpack_overview_products {

  .view-filters {

    .form-item label {
      margin-bottom: 7px;
    }

    textarea,
    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="tel"],
    input[type="url"],
    input[type="text"] {
      font-size: 16px;
      border: 1px solid;
      box-shadow: 0 0 1px transparent;
      padding: 5px;

      &:focus {
        outline: none !important;
        box-shadow: 0 0 1px $color-red-1;
      }

    }

    .selectize-control.single {
      min-width: 150px;
    }

    #edit-field-webpack-product-category-tid-wrapper {
      clear: both;
    }

    .form-submit {
      color: white;
      font-size: 16px;
      font-weight: 500;
      background: $color-grey-1;
      border: none;
      cursor: pointer;
      padding: 8px 20px;
      margin-top: 1em;

      &[disabled] {
        background: $color-grey-2;
      }

      &:not([disabled]):hover {
        background: $color-red-1;
      }

      &.alert {
        background: $color-red-1;

        &:hover {
          background: $color-grey-1;
        }

      }

    }

    form {

      > div > fieldset,
      > div > div {
        margin-top: 1em;
        margin-bottom: 3em;
      }

      fieldset {

        &.collapsed {

          legend a {
            color: $color-red-1;
          }

        }
      }

    }

    .form-actions {

      input {
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }

      }

    }
  }

  .views-exposed-form {

    .views-exposed-widget {
      padding: .5em 1em 10px 0;
    }

    .views-widget-filter-field_webpack_product_category_tid {
      padding: .5em 0 10px 0;

      .form-type-select {

        > select {
          padding: 5px;
          margin: 0 1.3em 10px 0;
        }

      }

    }

  }

  .view-content {

    .sticky-header {
      top: 40px !important;
      z-index: 2;
    }

    .fieldset-wrapper {
      > * {
        margin-right: 10px;
      }
    }

    .form-submit {
      color: white;
      font-size: 16px;
      font-weight: 500;
      background: $color-grey-1;
      border: none;
      cursor: pointer;
      padding: 8px 20px;
      margin-top: 1em;

      &[disabled] {
        background: $color-grey-2;
      }

      &:not([disabled]):hover {
        background: $color-red-1;
      }

      &.alert {
        background: $color-red-1;

        &:hover {
          background: $color-grey-1;
        }

      }

    }

    table {
      tr {
        th {
          padding: 10px;
        }
      }

      tr {

        &.even {
          background-color: white;
          border-bottom: none;
        }

        &.odd {
          background-color: $color-grey-4;
          border-bottom: none;
        }

        td {
          padding: 10px;
          vertical-align: top;

          &.views-field-field-webpack-product-category {

            .term-tree-list {
              max-height: 50px;
              overflow: hidden;

              &.expanded {
                max-height: none;
              }

              > ul {
                > li {
                  margin-bottom: 10px;
                }
              }


              ul {
                margin-left: 0;
                padding-left: 10px;

                li {
                  font-weight: normal;
                  font-size: 13px;
                }

              }
            }

          }

          &.views-field-field-webpack-product-project {

            > * {
              max-height: 50px;
              overflow: hidden;

              &.expanded {
                max-height: none;
              }

              ul {
                margin: 0;
                padding: 0;

                li {
                  margin: 0 !important;
                  margin-left: 20px !important;

                  a {
                    font-weight: normal;
                    font-size: 13px;
                  }

                }
              }
            }

          }

          > .term-tree-list,
          > .item-list {
            margin-bottom: 10px;

            &.expanded {
              margin-bottom: 0;
            }

            + a {
              @include btn-red-border;
              max-width: 31px;
              display: block;
              font-size: 28px;
              line-height: 1;
              text-align: center;
              padding: 0 4px;
            }

          }

          &.views-field-status {
            text-align: center;
          }

          &.views-field-edit-node {
            text-align: center;
          }

        }
      }
    }
  }

}
