.project-demo-digital {

  .title {

    h1 {
      color: $color-red-1;
      font-size: 24px;
      font-weight: 400;
      text-transform: uppercase;
      text-align: center;
      margin: 20px 0 40px;

      @include breakpoint ($desk) {
        font-size: 30px;
      }

      span {
        padding: 0 4px;

        &.block-item {
          display: block;
          padding-left: 0;
        }

        &.last {
          display: inline-block;
          color: $color-red-1;
          font-size: 24px;
          font-weight: 700;
          text-transform: uppercase;
          padding-right: 0;
          margin: 0;
          position: relative;

          @include breakpoint ($desk) {
            font-size: 30px;
          }

        }
      }
    }

  }

  > .item-list {

    @include breakpoint ($tab) {
      display: block;
      border-bottom: 1px solid #949494;
      padding-bottom: 20px;
      margin-bottom: 30px;
    }

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }

    > ul {
      display: block;
      padding: 0;
      margin: 0;

      @include breakpoint ($tab) {
        display: flex;
      }

      > li {
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;

        @include breakpoint ($tab) {
          width: 33.33333%;
        }

        .node {
          margin-bottom: 20px;

          @include breakpoint ($desk) {
            margin-bottom: 0;
          }

        }
      }
    }
  }

}
