.node-webpack-product.node-teaser {
  text-align: center;
  padding: 0 10px 40px;
  margin-bottom: 30px;

  .content a {
    display: inline-block;

    .field-name-field-webpack-product-img {
      margin-bottom: 10px;

      img {
        vertical-align: top;
      }

    }

    > h4 {
      font-weight: 600;
      font-size: 18px;
      background: url(image-url("icons/icon-download.png")) no-repeat center left;
      background-size: 18px;
      padding-left: 23px;
      margin: 0;
    }

  }

}
