.comment-form {

  > div {

    > .form-item {
      margin: 0 0 2em;

      &:not(.form-type-checkbox) {

        label {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 5px;

          @include breakpoint ($desk) {
            font-size: 19px;
            margin-bottom: 9px;
          }

          + a {
            display: block;
            font-size: 16px;
            border-bottom: 1px solid $color-grey-2;
            outline: none;
            padding: 0 2px;

            @include breakpoint ($desk) {
              font-size: 19px;
            }

          }
        }

      }

      &:nth-child(odd):not(.form-type-checkbox) {

        @include breakpoint ($desk) {
          padding-right: 10px;
        }

      }

      &:nth-child(even):not(.form-type-checkbox) {

        @include breakpoint ($desk) {
          padding-left: 10px;
        }

      }

    }

    > div[id*='edit-author'],
    > .form-item-name {

      @include breakpoint ($desk) {
        width: 50%;
        float: left;
      }

    }

    .field-name-field-comment-email {
      margin: 0 0 2em;

      @include breakpoint ($desk) {
        width: 50%;
        float: right;
        padding-left: 10px;
      }

      .form-item {
        margin: 0;
      }

    }

    .field-name-field-comment-subject {
      clear: both;
    }

    > .field-type-markup,
    > .field-name-comment-body {
      margin: 0 0 2em;

      .form-item {
        margin: 0;
      }

    }

  }

  .form-type-textarea {
    clear: both;
  }

  .form-actions {
    margin: 2em 0;
    clear: both;

    input {
      @include btn-red-border;
      margin-right: 1.5em;
      margin-bottom: 1.5em;

      @include breakpoint ($desk) {
        margin-bottom: 0;
      }

      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }

    }

  }

  .filter-wrapper {
    padding: 0;

    .fieldset-wrapper {
      padding: 1.5em 0 1.5em;
    }

  }

}
