.paragraphs-item-icons-list-text {

  > .content {

    > .field-name-field-icons-list-text-title {
      font-size: 36px;
      font-weight: 300;
      line-height: 1;
      text-align: center;
      margin-top: 15px;
      margin-bottom: 25px;

      @include breakpoint ($desk) {
        max-width: 85%;
        font-size: 48px;
        margin: 0 auto 100px;
      }

    }

    > .paragraphs-items-field-icons-list-text-items {

      > div > div {
        @include clearfix;
        padding: 0 5%;

        @include breakpoint ($desk) {

        }

        > .field-item {
          margin-bottom: 20px;

          @include breakpoint ($desk) {
            width: 50%;
            float: left;
            margin-bottom: 45px;

            &:nth-child(odd) {
              padding-right: 20px;
            }

            &:nth-child(even) {
              padding-left: 20px;
            }

          }

        }
      }
    }
  }

}
