.item-list ul.pager {
  float: right;
  margin: 1em 0;

  li {
    font-size: 19px;
    padding: 0;
    margin: 0;

    &.pager-current,
    &.pager-item {

      &:before {
        content: '/';
        display: inline-block;
        color: $color-grey-1;
        font-weight: bold;
        margin-right: 4px;
        margin-left: 1px;
      }

    }

    &.pager-current {
      color: $color-red-1;

      &.first:before {
        content: none;
      }

      + .firstNormalItem {

        &:before {
          content: "/";
        }

      }
    }

    &.firstNormalItem {

      &:before {
        content: none;
      }

    }

    &.pager-first,
    &.pager-previous,
    &.pager-next,
    &.pager-last {

      a {
        display: inline-block;
        width: 22px;
        height: 22px;
        text-indent: -10000em;
      }

    }

    &.pager-first {
      margin-right: 1px;

      a {
        background: url(image-url("icons/pager-double-check.svg")) no-repeat top center;
        background-size: contain;
      }

    }

    &.pager-previous {

      &:after {
        content: "...";
        display: inline-block;
        font-size: 30px;
        letter-spacing: 3px;
        margin-left: 6px;
      }

      a {
        background: url(image-url("icons/pager-check.svg")) no-repeat top center;
        background-size: contain;
      }

    }

    &.pager-next {

      &:before {
        content: "...";
        display: inline-block;
        font-size: 30px;
        letter-spacing: 3px;
        margin-right: 6px;
      }

      a {
        background: url(image-url("icons/pager-check.svg")) no-repeat top center;
        background-size: contain;
        transform: rotate(180deg);
      }

    }

    &.pager-last {
      margin-left: 1px;

      a {
        background: url(image-url("icons/pager-double-check.svg")) no-repeat top center;
        background-size: contain;
        transform: rotate(180deg);
      }

    }

    a {
      color: $color-grey-1;
      font-size: 19px;

      &:hover {
        opacity: .7;
      }

    }
  }

}
