.paragraphs-item-cards-group {
  padding: 30px 15px;

  @include breakpoint ($desk) {
    padding: 60px 20px;
  }

  .field-name-field-cards-group-text {
    margin-bottom: 40px;

    @include breakpoint ($desk) {
      margin-bottom: 70px;
    }

    * {
      font-family: $font-exo2;
    }

    h1, h2, h3, h4, h5, h6 {
      font-weight: initial;
      margin: 0 0 .5em;

      @include breakpoint ($desk) {
        margin: 0 0 1em;
      }

    }

    p {
      margin: 0 0 1em;
    }

  }

  .field-name-field-cards-group-cards {

    > .field-items {

      @include breakpoint ($tab) {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: stretch;
      }

      > .field-item {
        margin-bottom: 40px;

        @include breakpoint ($tab) {
          width: 33.3333%;
          padding: 0 20px;
          margin-bottom: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

      }
    }
  }

}
