.page-taxonomy-term- {

  .block-sm-breadcrumb-and-title {
    display: none;
  }

  .block-system > .content {

    > .taxonomy-term {

      .field-label {
        display: none;
      }

      .field-item {
        color: $color-red-1;
        font-size: 24px;
        font-weight: 700;
        line-height: inherit;
        text-transform: uppercase;
        margin-top: 20px;
        margin-bottom: 40px;

        @include breakpoint ($desk) {
          font-size: 30px;
        }

      }
    }

  }

  // Placed the styles of the Book CT teaser VM on this page
  // because we do not know the other sites where it is used.
  .node--book--teaser {
    margin-bottom: 20px;

    .content {
      display: flex;
      text-align: left;
    }

    .field-name-field-book-cover-image {
      width: 100px;
      padding-right: 20px;
      text-align: center;
    }

    .book-teaser-content {
      width: 100%;

      > h2 {
        font-size: 0;
        margin: 0 0 10px;

        a {
          font-size: 24px;
          font-family: $font-exo2;
        }

      }

    }

    .field-name-field-book-authors {
      margin-bottom: 5px;

      h2 a {
        font-size: 16px;
        line-height: 1.3;
        transition: all .4s;

        &:hover {
          color: $color-red-1;
        }

      }

      .field-item {
        display: inline-block;
        padding-right: 12px;
        margin-right: 12px;
        position: relative;

        &:last-child {
          padding-right: 0;
          margin-right: 0;

          &:after {
            content: none;
          }

        }

        &:after {
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          background: $color-grey-1;
          border-radius: 100%;
          transform: translateY(-50%);
          position: absolute;
          top: 50%;
          right: -3px;
        }

      }

    }

    .field-name-field-book-price-iva {

      .field-item {
        color: $color-grey-1-5;
        font-size: 18px;
        font-weight: 500;
        font-family: $font-fira-sanz-condensed;
        text-transform: uppercase;
        margin: 7px 0 0;
      }

    }

    .read-more {
      display: block;
      color: $color-red-1;
      font-size: 16px;
      font-family: $font-exo2;
      font-weight: bold;
      text-align: right;
      text-transform: uppercase;
      position: relative;
      padding-right: 16px;

      &:hover {
        opacity: .7;
      }

      &:after {
        content: "";
        display: inline-block;
        width: 12px;
        height: 15px;
        background: url(image-url("icons/arrow-red-medium.svg")) no-repeat center;
        background-size: contain;
        transform: translate(0, -50%);
        position: absolute;
        top: 50%;
        right: 0;
      }

    }
  }

}
