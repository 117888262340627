#sliding-popup.sliding-popup-bottom {
  background: $color-red-1;

  .popup-content #popup-buttons button {
    display: inline-block;
    color: white;
    font-weight: 600;
    font-size: 14px;
    background: $color-red-1;
    border-radius: 3px;
    text-shadow: none;
    box-shadow: none;
    padding: 7px 8px 5px;
    margin-top: 6px;

    &:hover {
      opacity: .8;
    }

  }

}
