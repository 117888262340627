// Reset
ul.contextual-links li {
  display: block;

  a {
    display: block;
    font-family: initial;
    font-weight: initial;
  }

}
