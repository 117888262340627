.block-sm-breadcrumb-and-title {
  margin-bottom: 12px;

  > .content {

    // Reset.-
    ul {
      padding: 0;
      margin: 0;

      li {
        list-style: none;
      }

    }

    // Styles
    > ul {
      position: relative;

      &.split-true {
        @include clearfix;

        > li {
          float: left;

          &:nth-child(-n+5) {
            color: #6e6e6e;
            font-size: 16px;

            @include breakpoint ($desk) {
              font-size: 18px;
              margin-bottom: 6px;
            }

            > a, > span {
              color: #6e6e6e;
              font-size: 16px;
              line-height: inherit;

              @include breakpoint ($desk) {
                font-size: 18px;
              }

            }

          }

          &:nth-child(6).separator {
            width: 0;
            height: 0;
            opacity: 0;
            clear: left;
          }

          &.separator {
            position: relative;
            top: -1px;
          }

        }

      }

      > li {
        display: inline-block;
        color: $color-red-1;
        vertical-align: top;
        padding-right: 4px;

        &:not(.active) {
          font-size: 24px;
          font-weight: 400;
          line-height: inherit;
          text-transform: uppercase;

          @include breakpoint ($desk) {
            font-size: 30px;
          }
        }

        &.active {

          > ul {
            height: auto;
            opacity: 1;
            box-shadow: 0 19px 16px 0 rgba(50, 50, 50, 0.35);
            padding: 18px 20px 16px;
            top: 100%;
            z-index: 2;
          }

          @include breakpoint ($tab) {
            position: relative;
          }

          h1 {
            &.has-siblings {
              &:after {
                transform: rotate(0deg);
              }
            }

            + ul li.nolink {
              display: none;
            }

          }

        }

        &.separator {
          color: $color-red-1;
          font-size: 24px;
          line-height: inherit;

          @include breakpoint ($desk) {
            font-size: 30px;
          }

        }

        &:last-child {
          padding-right: 0;
        }

        > a, > span {
          display: inline-block;
          color: $color-red-1;
          font-size: 24px;
          font-weight: 400;
          line-height: inherit;
          text-transform: uppercase;

          @include breakpoint ($desk) {
            font-size: 30px;
          }

        }

        > h1 {
          display: inline-block;
          color: $color-red-1;
          font-size: 24px;
          font-weight: 700;
          line-height: 0.9;
          text-transform: uppercase;
          padding-right: 24px;
          margin: 0;
          position: relative;

          @include breakpoint ($desk) {
            font-size: 30px;
            padding-right: 28px;
          }

          &.has-siblings {
            cursor: pointer;

            &:hover {
              opacity: .8;
            }

            &:after {
              content: "";
              display: inline-block;
              width: 16px;
              height: 12px;
              background: url(image-url("icons/chevron-red-bold.svg")) no-repeat center;
              background-size: contain;
              transform: rotate(180deg);
              transition: all 200ms ease-in-out;
              position: absolute;
              top: 10px;
              right: 0;

              @include breakpoint ($desk) {
                width: 20px;
                height: 14px;
                top: 14px;
              }

            }
          }

        }

        > ul {
          width: calc(100% + 30px);
          height: 0;
          opacity: 0;
          background: white;
          z-index: 1;
          position: absolute;
          top: -100em;
          right: -15px;

          @include breakpoint ($tab) {
            width: auto;
            min-width: 250px;
            left: -15px;
          }

          > li {
            display: block;
            border-bottom: 1px solid $color-grey-1;

            &:last-child {
              border-bottom: none;
            }

            > a, > span {
              display: inline-block;
              font-size: 16px;
              line-height: inherit;
              padding: 8px 5px 10px;

              @include breakpoint ($tab) {
                padding: 8px 15px 10px 5px;
              }

              &:hover {
                color: $color-red-1;
              }

            }
          }
        }
      }

    }

    .history-back {
      display: inline-block;
      margin-top: 3px;

      &:before {
        content: '';
        width: 14px;
        height: 15px;
        display: inline-block;
        background: url(image-url("icons/arrow-red-fine.svg")) no-repeat center;
        background-size: contain;
        transform: rotate(180deg);
        margin-right: 5px;
        position: relative;
        top: 2px;

        @include breakpoint ($desk) {
          margin-right: 7px;
        }

      }
    }
  }

}
