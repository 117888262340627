div[id^="block-sm-custom-blocks-footer-social-links"] {
  display: table;

  .content {
    max-width: 160px;
    display: table-cell;
    vertical-align: middle;

    ul {
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        display: inline-block;
        margin-right: 13px;

        @include breakpoint ($desk) {
          margin-right: 20px;
        }

        &:last-child {
          margin-right: 0;
        }

        &.ig {

          a {
            font-size: 17px;

            @include breakpoint ($desk) {
              font-size: 19px;
            }

          }

        }

        a {
          color: white;
          font-size: 19px;
          text-decoration: none;

          @include breakpoint ($desk) {
            font-size: 22px;
          }

          span {
            display: none;
          }

          &:hover {
            opacity: .7;
          }

        }

      }
    }
  }

}
