.page-admin-structure-taxonomy-sm-webpack {
  .contextual-links-region {
    #tabs {
      display: none;
    }
  }
}


#taxonomy-overview-terms {

  table {
    tr {
      th {
        padding: 10px;
      }
    }

    tr {

      &.even {
        background-color: white;
        border-bottom: none;
      }

      &.odd {
        background-color: $color-grey-4;
        border-bottom: none;
      }

      td {
        padding: 10px;

        .lang-link {
          text-indent: -9999em;
          display: inline-block;
          width: 16px;
          height: 11px;
          vertical-align: middle;

          &.lang-not-translated {
            opacity: .4;
          }

          &.lang-es {
            background: url(image-url("flag-es-16x11.png")) no-repeat center;
          }

          &.lang-pt, &.lang-pt-br {
            background: url(image-url("flag-pt-16x11.png")) no-repeat center;
          }

          &.lang-en {
            background: url(image-url("flag-gb-16x11.png")) no-repeat center;
          }
        }

      }
    }
  }

}
