.webform-client-form {

  @include breakpoint($tab) {
    width: 80%;
    margin: 0 auto;
  }

  > div > .form-item {
    margin-bottom: 2em;
  }

  .webform-component--name {

    @include breakpoint($tab) {
      width: 50%;
      float: left;
      padding-right: 10px;
      box-sizing: border-box;
    }

  }

  .webform-component--email {
    @include breakpoint($tab) {
      width: 50%;
      float: right;
      padding-left: 10px;
      box-sizing: border-box;

      &.full-width {
        width: 100%;
        float: none;
        padding: 0;
      }
    }

  }

  .webform-component--phone {

    @include breakpoint($tab) {
      width: 50%;
      float: left;
      padding-right: 10px;
      box-sizing: border-box;
    }

  }

  .webform-component--address {

    @include breakpoint($tab) {
      width: 50%;
      float: right;
      padding-left: 10px;
      box-sizing: border-box;
    }

  }

  .webform-component-checkboxes {
    > label {
      display: none;
    }

    .form-type-checkbox {
      @include checkbox;
    }
  }

  .webform-component--subject {
    clear: both;

    label {
      margin-bottom: .5em;
    }
  }

  .form-actions {

    input {
      @include btn-red-border;

      &#edit-cancel {
        display: none;
      }
    }

  }

}
