.node-type-material-display {

  &:not(.logged-in) {

    .region-container-content .col {

      @include breakpoint ($desk) {
        padding-left: 0;
        padding-right: 0;
      }

    }
  }

}
