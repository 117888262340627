// Colocamos fixed la cabecera.
body {

  &.front {
    .region-container-header {
      width: 100%;
      position: absolute;
      top: 0;

      @include breakpoint ($desk) {
        max-width: 1170px;
        position: fixed;
      }

    }

    &.logged-in {

      .region-container-header {

        @include breakpoint ($desk) {
          max-width: 1185px;
          top: 29px;
        }

      }

      &.sm-role {

        .region-container-header {
          @include breakpoint ($desk) {
            top: 39px;
          }

        }
      }
    }

  }

  &:not(.front) {
    .region-container-header {

      @include breakpoint ($desk) {
        max-width: 1185px;
      }

    }

    &.logged-in {

      .region-container-header {

        @include breakpoint ($desk) {
          top: 29px;
        }

      }

      &.sm-role {

        .region-container-header {
          @include breakpoint ($desk) {
            top: 39px;
          }

        }
      }
    }

  }

  .region-container-header {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 15px;

    @include breakpoint ($tab) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    @include breakpoint ($desk) {
      z-index: 10;
      margin: 0 auto;
      position: fixed;
      top: 0;
      left: 0;
    }

    @include breakpoint ($desk-lg) {
      left: 50%;
      transform: translate(-50%, 0);
    }

  }

}

.block-sm-custom-blocks.open + .block-system + div .region-container-header.header-offset {
  background: transparent;

  .region-header {
    background: white;
  }

}
