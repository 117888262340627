.paragraphs-item-header-text-image {
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;

  @include breakpoint ($desk) {
    padding-top: 110px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .field-name-field-header-image-text {
    margin-bottom: 30px;

    @include breakpoint ($desk) {
      margin-bottom: 45px;
    }

    * {
      font-family: $font-exo2;
    }

    h1, h2, h3, h4, h5, h6 {
      font-weight: initial;
      margin: 0 0 .6em;
    }

    p {
      margin: 0;
    }

  }

  .field-name-field-header-image-image {
    text-align: center;
  }

}
