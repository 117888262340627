.view-display-id-page_events {

  .views-row {
    border-bottom: 1px solid #A6A6A6;
    padding-bottom: 20px;
    margin-bottom: 20px;

    &:last-child {
      border-bottom: none;
    }

  }

}
