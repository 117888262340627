#sm-search-form {

  .form-type-textfield {
    $custom-grey: #B6B6B6;
    padding-top: 97px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0;

    @include breakpoint ($desk) {
      padding-top: 120px;
    }

    input {
      max-width: 100%;
      color: $custom-grey;
      font-size: 26px;
      font-family: $font-exo2;
      font-weight: 300;
      outline: none;
      border: none;
      transition: all 500ms;
      background: url(image-url("icons/lens.svg")) no-repeat bottom -1px left 35%;
      background-size: 22px 100%;
      padding-left: 35px;

      @include breakpoint ($desk) {
        font-size: 30px;
        background: url(image-url("icons/lens.svg")) no-repeat bottom -1px left 33%;
        background-size: 26px 100%;
      }

      @include placeholder {
        color: $custom-grey;
        text-align: center;
      }

      &.empty {
        background: url(image-url("icons/lens.svg")) no-repeat bottom -1px left 33%;
        background-size: 26px 100%;
      }

      &.has-content,
      &:focus {
        color: $color-grey-1;
        border-bottom: 2px solid $custom-grey;
        background: url(image-url("icons/lens.svg")) no-repeat bottom -1px left 0;
        background-size: 26px 100%;
      }

    }

    + input {
      display: none;
    }

  }

}
