.node--book--teaser {

  .content {
    text-align: center;

    .field-name-field-book-cover-image {
      margin-bottom: 15px;

      img {
        margin: 0 auto;
      }

    }

    .book-teaser-content > h2 {
      font-size: 14px;
      font-weight: bold;
      margin: 0 0 5px;

      a {
        font-size: 14px;
      }

    }

    .field-name-field-book-authors {
      font-size: 14px;
      margin-bottom: 5px;

      .content {
        display: none;
      }

      h2 {
        font-size: 14px;
        line-height: 1;
        margin: 0;

        a {
          color: $color-grey-1;
          font-size: 14px;
          font-weight: normal;
        }

      }

    }

    .field-name-field-book-price-iva {
      font-size: 14px;

      .field-item {

        &:after {
          content: "€";
          display: inline-block;
          margin-left: 4px;
        }

      }

    }

    .read-more {
      display: none;
    }

  }

}
