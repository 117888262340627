.paragraphs-item-slider-style-4 {
  position: relative;

  .paragraphs-item-slider-style-4-slides {
    position: relative;

    .field-name-field-slider-style-4-slide-img {

      img {
        width: 100%;
        height: auto;
      }

    }

    > .content {
      display: flex;
      width: 100%;
      height: 100%;
      padding-top: 15px;
      padding-bottom: 54px;
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;

      @include breakpoint ($tab) {
        padding-bottom: 95px;
      }

      @include breakpoint ($desk) {
        padding-top: 40px;
      }

      .field-name-field-slider-style-4-slide-cont {
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 10px 10px;

        @include breakpoint ($desk) {
          display: block;
          align-self: center;
          padding: 0 30px 0 66%;
        }

      }

      .read-more-link-wrapper {
        position: absolute;
        left: 10px;
        bottom: 10px;

        @include breakpoint ($tab) {
          position: absolute;
          left: initial;
          right: 13px;
          bottom: 50px;
        }

        &.button-red {

          a {
            display: inline-block;
            color: $color-red-1;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            background-color: transparent;
            border: 2px solid $color-red-1;
            cursor: pointer;
            transition: all 250ms;
            padding: 6px 25px;

            &:hover {
              color: white;
              background-color: $color-red-1;
            }
          }

        }

        &.button-white {

          a {
            display: inline-block;
            color: white;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            background-color: transparent;
            border: 2px solid white;
            cursor: pointer;
            transition: all 250ms;
            padding: 6px 25px;

            &:hover {
              color: black;
              background-color: white;
            }
          }

        }

        &.arrow-text-white {

          a {
            display: inline-block;
            color: white;
            font-size: 16px;
            text-transform: uppercase;
            position: relative;
            white-space: nowrap;
            padding: 10px 30px 10px 10px;

            @include breakpoint ($desk) {
              font-size: 20px;
              padding: 0 28px 0 0;
            }

            &:after {
              content: "";
              display: inline-block;
              width: 15px;
              height: 15px;
              background: url(image-url("icons/arrow-white.svg")) no-repeat center;
              background-size: contain;
              transform: translate(0, -50%);
              position: absolute;
              top: 50%;
              right: 9px;

              @include breakpoint ($desk) {
                width: 23px;
                height: 19px;
                right: 0;
              }

            }
          }

        }

        &.arrow-text-red {

          a {
            display: inline-block;
            color: $color-red-1;
            font-size: 16px;
            text-transform: uppercase;
            position: relative;
            white-space: nowrap;
            padding: 10px 30px 10px 10px;

            @include breakpoint ($desk) {
              font-size: 20px;
              padding: 0 28px 0 0;
            }

            &:after {
              content: "";
              display: inline-block;
              width: 15px;
              height: 15px;
              background: url(image-url("icons/arrow-right-red.svg")) no-repeat center;
              background-size: contain;
              transform: translate(0, -50%);
              position: absolute;
              top: 50%;
              right: 9px;

              @include breakpoint ($desk) {
                width: 23px;
                height: 19px;
                right: 0;
              }

            }
          }
        }

      }


      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: $font-fira-sanz-condensed;
        font-weight: normal;
        line-height: 1;
        text-align: right;
        margin-top: 0;
        margin-bottom: 16px;
      }

      p {
        margin: 0;
      }

    }

  }

  // Controls
  .slider-nav-wrapper {
    width: 50%;
    padding-top: 4px;
    position: absolute;
    right: 26px;
    bottom: 17px;

    @include breakpoint ($tab) {
      bottom: 21px;
    }

    .slick-dots {
      float: right;
    }

  }

}
