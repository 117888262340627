.node--material-display--full {

  .content {

    > div {

      @include breakpoint ($desk) {
        float: left;
      }

    }

  }

  .left-wrapper {
    padding-top: 6px;
    margin-bottom: 22px;

    @include breakpoint ($desk) {
      width: 29%;
      padding-top: 7px;
      margin-bottom: 0;
    }

    > div {
      float: left;

      @include breakpoint ($desk) {
        float: none;
      }

    }

    .commerce-product-field-field-sm-ecat-portada {
      width: 44%;

      @include breakpoint ($desk) {
        width: 81%;
      }

      img {
        vertical-align: top;
      }

    }

    .info-header {
      width: 56%;
      padding-top: 13px;
      padding-right: 30px;
      padding-left: 14px;
    }

  }

  .right-wrapper {

    @include breakpoint ($desk) {
      width: 71%;
      padding-left: 5px;
    }

    > div {

      @include breakpoint ($desk) {
        float: left;
      }

    }

    .info {

      @include breakpoint ($desk) {
        width: 74.5%;
        padding-top: 3px;
        padding-right: 45px;
      }

      .left-info,
      .right-info {

        @include breakpoint ($desk) {
          width: 50%;
          float: left;
        }

      }

      .left-info > div {
        border-bottom: 1px solid;
        padding: 5px 0;

        @include breakpoint ($desk) {
          padding: 6px 0;
        }

        &:last-child {
          border-bottom: none;
        }

        .commerce-product-sku-label,
        .field-label {
          display: inline-block;
          font-size: 14px;
          font-weight: 300;
          line-height: 1.5;
          margin-right: 2px;
        }

        .field-items {
          display: inline-block;
        }

        .commerce-product-sku,
        .field-item {
          display: inline-block;
          color: #4D4D4D;
          font-size: 14px;
          font-weight: 700;
          vertical-align: inherit;
          padding-right: 4px;

          &:last-child {
            padding-right: 0;

            &:after {
              content: none;
            }

          }

          &:after {
            content: ",";
            display: inline-block;
          }

        }
      }
    }

  }

  // Fields.
  .price {
    color: #4D4D4D;
    max-width: 185px;

    @include breakpoint ($desk) {
      display: block !important;
      max-width: inherit;
    }

    .wrapper {
      background: #EDEDED;
      padding: 6px 12px 10px;

      @include breakpoint ($desk) {
        background: transparent;
        padding: 0;
      }

      .commerce-product-field-commerce-price {
        font-size: 20px;
        font-weight: 700;
        text-align: center;

        @include breakpoint ($desk) {
          font-size: 29px;
          text-align: left;
        }

      }

      span {
        display: block;
        font-size: 9px;
        font-family: $font-fira-sanz-condensed;
        line-height: 9px;

        @include breakpoint ($desk) {
          font-size: 10px;
          line-height: 12px;
        }

      }
    }

  }

  .field-name-title-field {
    margin-bottom: 17px;

    @include breakpoint ($desk) {
      margin-bottom: 19px;
    }

    h2 {
      color: $color-red-1;
      font-size: 20px;
      line-height: 23px;
      margin: 0;

      @include breakpoint ($desk) {
        font-size: 23px;
        line-height: 1;
      }

    }

  }

  .field-name-field-sm-ecat-sinopsis {
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
    clear: both;
    margin-bottom: 18px;

    @include breakpoint ($desk) {
      font-size: 16px;
      line-height: 1.3;
      margin-bottom: 20px;
    }

    p:last-child {
      margin-bottom: 0;
    }

  }

}
