html.js .ajax-progress {
  background: white;
  transform: translate(-50%, -50%);
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;

  .throbber {
    width: 20px;
    height: 20px;
    background: url(image-url("ajax-loader.gif")) no-repeat center transparent;
    background-size: contain;
    float: none;
    margin: -10px 0 0;
    position: absolute;
    top: 50%;
    left: 20px;
  }

  .message {
    padding-left: 30px;
  }

}