.contact-form {

  @include breakpoint($tab) {
    width: 80%;
    margin: 0 auto;
  }

  > div > .form-item {
    margin-bottom: 2em;
  }

  .form-item-name {

    @include breakpoint($tab) {
      width: 50%;
      float: left;
      padding-right: 10px;
      box-sizing: border-box;
    }

  }

  .form-item-mail {

    @include breakpoint($tab) {
      width: 50%;
      float: right;
      padding-left: 10px;
      box-sizing: border-box;
    }

  }

  .form-item-subject {
    clear: both;
  }

  .form-type-textarea {
    @include textarea;
  }

  .form-type-checkbox {
    @include checkbox;
  }

  .form-actions {

    input {
      @include btn-red-border;
    }

  }

}
