#sliding-popup.sliding-popup-bottom {

  .popup-content {

    #popup-text {

      h1,
      h2,
      h3,
      p {
        font-size: 13px;
        font-weight: normal;
      }

    }

    div#popup-buttons {
      margin: 0;

      button {
        font-size: 12px;
      }

    }
  }

}