.top-trigger-button {
  display: inline-block;
  width: 45px;
  height: 0;
  text-indent: 1000em;
  background: url(image-url("icons/upload-arrow-bold.svg")) no-repeat 50% 55% rgba(0, 0, 0, .5);
  background-size: 15px;
  border-radius: 50%;
  transition: opacity 1s, height .4s;
  overflow: hidden;
  z-index: 999;
  cursor: pointer;
  opacity: 0;
  position: fixed;
  bottom: 40px;

  &.show {
    height: 45px;
    opacity: 1;
  }

  &:hover, &:active {
    background: url(image-url("icons/upload-arrow-bold.svg")) no-repeat 50% 55% rgba(0, 0, 0, 1);
    background-size: 15px;
  }

}
