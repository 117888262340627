.paragraphs-item-content-card {
  height: 100%;
  $borderRadius: 4px;

  .content {
    height: 100%;
    background: white;
    border-radius: $borderRadius;
    box-shadow: 0 7px 25px 0 rgba(206, 206, 206, 0.5);
  }

  .field-name-field-content-card-image {

    img {
      width: 100%;
      height: auto;
      border-radius: $borderRadius $borderRadius 0 0;
    }

  }

  .group-info-text {
    padding: 16px 20px 25px;

    @include breakpoint ($desk) {
      padding: 22px 30px 33px;
    }

  }

  .field-name-field-content-card-title {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.2;

    @include breakpoint ($desk) {
      font-size: 26px;
      line-height: 31px;
    }

  }

  .field-name-field-content-card-link {
    margin-top: 30px;

    @include breakpoint ($desk) {
      margin-top: 67px;
    }

    a {
      display: inline-block;
      color: $color-red-1;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.1;
      background: url(image-url("icons/icon-circle-more.png")) no-repeat left;
      background-position: bottom left;
      background-size: 16px;
      padding-left: 29px;
      position: relative;

      @include breakpoint ($desk) {
        font-size: 19px;
        background-position: center left;
      }

      &:hover {
        opacity: .8;
      }

    }
  }

}
