#comments {
  color: $color-grey-1;
  font-size: 14px;

  @include breakpoint ($desk) {
    font-size: 16px;
    padding-left: 7%;
    padding-right: 7%;
  }

  .title:not(.comment-form) {
    color: $color-red-1;
    margin: 0 0 .5em;
  }

  .comment {
    border-top: 1px solid $color-grey-1;
    padding-top: 20px;
    padding-bottom: 25px;

    h3 {
      margin: 0 0 1em;
    }

    .links {

      a {
        color: $color-red-1;

        &:hover {
          color: $color-grey-1;
        }

      }
    }

  }

  #comment-1 + .comment {
    padding-top: 0;
    border-top: none;
  }

  h2.comment-form {
    color: $color-red-1;
  }

  form{
    .form-type-checkbox{
      width: 100%;
      float: none;
      line-height: 1.2;
    }
  }

}
