.block--views--similarterms {
  margin: 30px 0 0;

  @include breakpoint ($desk) {
    margin: 30px 20px 7px;
  }

  > h2 {
    color: $color-red-1;
    margin: 0;
    margin-bottom: 30px;
    font-size: 30px;
    text-transform: uppercase;
  }

  > .content {

    .view-content {
      @include flex-grid(2, 15px);
    }

  }

  > h3 {
    text-align: right;
    font-size: 16px;
    line-height: normal;
    margin: 32px 0 0;

    @include breakpoint ($desk) {
      font-size: 20px;
      margin: 37px 0 0;
    }

    a {
      color: $color-red-1;
      text-transform: uppercase;
      position: relative;

      &:after {
        content: '';
        width: 14px;
        height: 15px;
        display: inline-block;
        background: url(image-url("icons/arrow-red-fine.svg")) no-repeat center;
        background-size: contain;
        margin-left: 5px;
        position: relative;
        top: 2px;

        @include breakpoint ($desk) {
          width: 23px;
          height: 20px;
          margin-left: 7px;
        }

      }
    }
  }

}
