.node--material-display--search-result {

  .info-top {
    color: #C1C1C1;
    font-size: 12px;
    margin-bottom: 6px;

    @include breakpoint ($tab) {
      font-size: 14px;
    }

    > div {
      display: inline-block;
    }

    .category {
      text-transform: uppercase;
    }

  }

  .read-more {
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    transition: all 200ms;

    @include breakpoint ($tab) {
      font-size: 24px;
    }

    a {
      display: inline-block;

      &:hover {

        &:after {
          opacity: 1;
        }

      }

      &:after {
        content: "";
        display: inline-block;
        width: 21px;
        height: 17px;
        background: url(image-url("icons/arrow-red-bold.svg")) no-repeat center;
        background-size: contain;
        transition: all 200ms;
        opacity: 0;
        margin-left: 3px;
        position: relative;
        top: 2px;
      }

    }
  }

}
