.paragraphs-item-image-text {
  position: relative;

  &.with-bg-img {
    > .content {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .enlarge {
    display: none;
    z-index: 2;
    position: absolute;
    right: 0;
    top: 0;

    @include breakpoint ($desk) {
      display: block;
    }

    a {
      display: block;
      width: 30px;
      height: 30px;
      background: url(image-url("icons/icon-magnified-more.png")) no-repeat center white;
      outline: none;

      &:hover {
        opacity: .9;
      }

      img {
        display: none;
      }
    }

    + .content {
      padding: 24px 23px 45px;

      @include breakpoint ($desk) {
        padding: 50px 65px 96px;
      }

    }

  }

  > .content {
    position: static;
    padding: 16px 23px 45px;

    @include breakpoint ($desk) {
      padding: 50px 65px 96px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-transform: inherit;
      font-weight: inherit;
      margin: 0 0 0.3em;

      @include breakpoint ($desk) {
        margin: 0 0 0.6em;
      }

    }

    p {
      font-weight: 400;
      margin: 0 0 1em;

      @include breakpoint ($desk) {
        font-weight: 300;
      }

      &:last-child {
        margin-bottom: 0;
      }

    }

  }

  .field-name-field-p-txt-img-image {
    img {
      width: 100%;
    }
  }

  .field-name-field-p-txt-img-cta {
    position: absolute;
    right: 6%;
    bottom: 6%;

    @include breakpoint ($desk) {
      right: 39px;
      bottom: 44px;
    }

    a {
      font-size: 14px;
      text-transform: uppercase;
      position: relative;

      @include breakpoint ($desk) {
        font-size: 19px;
      }

      &[class*="text-color-"] {
        padding-right: 17px;

        @include breakpoint ($desk) {
          padding-right: 31px;
        }

        &:after {
          content: "";
          display: inline-block;
          width: 14px;
          height: 14px;
          transform: translate(0, -50%);
          position: absolute;
          top: 50%;
          right: 0;

          @include breakpoint ($desk) {
            width: 25px;
            height: 25px;
          }

        }

      }

      &.text-color-000000:after {
        background: url(image-url("arrow/arrow-black.png")) no-repeat center;
        background-size: contain;
      }

      &.text-color-FFFFFF:after {
        background: url(image-url("arrow/arrow-white.png")) no-repeat center;
        background-size: contain;
      }

      &.text-color-FF4C4C:after {
        background: url(image-url("arrow/arrow-ff4c4c.png")) no-repeat center;
        background-size: contain;
      }

      &.text-color-A6A6A6:after {
        background: url(image-url("arrow/arrow-a6a6a6.png")) no-repeat center;
        background-size: contain;
      }

      &.text-color-4D4D4D:after {
        background: url(image-url("arrow/arrow-4d4d4d.png")) no-repeat center;
        background-size: contain;
      }

      &.text-color-F3F2F3:after {
        background: url(image-url("arrow/arrow-f3f2f3.png")) no-repeat center;
        background-size: contain;
      }

      &.text-color-CDCBCD:after {
        background: url(image-url("arrow/arrow-cdcbcd.png")) no-repeat center;
        background-size: contain;
      }

      &.text-color-F4F4F4:after {
        background: url(image-url("arrow/arrow-f4f4f4.png")) no-repeat center;
        background-size: contain;
      }

      &.text-color-EE0000:after {
        background: url(image-url("arrow/arrow-ee0000.png")) no-repeat center;
        background-size: contain;
      }

      &.text-color-C78EBF:after {
        background: url(image-url("arrow/arrow-c78ebf.png")) no-repeat center;
        background-size: contain;
      }

      &.text-color-FFD530:after {
        background: url(image-url("arrow/arrow-ffd530.png")) no-repeat center;
        background-size: contain;
      }

      &.text-color-61B732:after {
        background: url(image-url("arrow/arrow-61b732.png")) no-repeat center;
        background-size: contain;
      }

      &.text-color-C7DC69:after {
        background: url(image-url("arrow/arrow-c7dc69.png")) no-repeat center;
        background-size: contain;
      }

      &.text-color-354D5F:after {
        background: url(image-url("arrow/arrow-354d5f.png")) no-repeat center;
        background-size: contain;
      }

      &.text-color-6DCEf5:after {
        background: url(image-url("arrow/arrow-6dcef5.png")) no-repeat center;
        background-size: contain;
      }

      &.text-color-59C4C6:after {
        background: url(image-url("arrow/arrow-59c4c6.png")) no-repeat center;
        background-size: contain;
      }

    }
  }

}

.layout-content .paragraphs-item-image-text .enlarge {
  top: 36px;
}
