#user-login {

  > div {

    @include breakpoint ($desk) {
      padding: 5%;
    }

    > .form-item {
      margin: 0 0 40px;

      input {
        padding: 7px 10px;
      }

    }

    .form-actions {

      input {
        @include btn-red-border;
        padding: 11px 16px 9px;
      }

    }
  }

}
