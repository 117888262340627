.paragraphs-item-slider-style-6 {

  .field-name-field-slider-style-6-title {
    font-size: 36px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 25px;

    @include breakpoint ($desk) {
      font-size: 48px;
      margin-bottom: 40px;
    }

  }

  .slick-slide {
    outline: none;
  }

  .field-name-field-slider-style-6-slides {
    position: relative;
    padding-bottom: 30px;

    @include breakpoint ($desk) {
      padding-bottom: 40px;
    }

    .slider-nav-wrapper {
      transform: translate(-50%, 0);
      position: absolute;
      left: 50%;
      bottom: 0;

      ul {
        padding: 0;

        li {
          margin-right: 13px;

          &:last-child {
            margin-right: 0;
          }

        }

      }

      .slick-active button {
        background: $color-grey-1;
      }

      button {
        width: 15px;
        height: 15px;
        background: $color-grey-3;
        border-radius: 50%;
      }

    }

    .paragraphs-item-slider-style-6-slides {

      > div {

        @include breakpoint ($tab) {
          width: 50%;
          float: left;
        }

      }

      .field-name-field-slider-style-6-slide-img {
        padding-bottom: 20px;

        @include breakpoint ($tab) {
          padding-top: 6px;
          padding-right: 20px;
          padding-bottom: 0;
        }

        @include breakpoint ($desk) {
          padding-right: 31px;
        }

        img {
          width: 100%;
          height: auto;
        }

      }

      .content {

        @include breakpoint ($tab) {
          padding-left: 10px;
          padding-right: 10px;
        }

        @include breakpoint ($desk) {
          padding-left: 50px;
          padding-right: 15px;
        }

      }

      .field-name-field-slider-s6-see-more-link {
        margin-top: 20px;

        @include breakpoint ($desk) {
          margin-top: 40px;
        }

        a {
          display: inline-block;
          color: white;
          font-size: 16px;
          font-weight: 600;
          background: red;
          border-radius: 4px;
          padding: 10px 20px 11px;

          @include breakpoint ($desk) {
            padding: 10px 43px 11px;
          }

          &:hover {
            background: #ff0000ba;
          }

        }

      }

      h1, h2, h3, h4, h5, h6 {
        font-weight: inherit;
        text-transform: uppercase;
        margin: 0;
      }

      p {
        font-family: $font-exo2;
      }

    }
  }

}
