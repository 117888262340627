.node-event-form {
  padding: 30px 0;

  .col {

    > fieldset,
    > div:not(.form-actions):not(.vertical-tabs) {
      margin: 0 0 2.5em;
    }

    > .field-name-field-event-category {
      border: 1px solid;
      padding: 1em;
    }

  }

  .group-gallery {

    .fieldset-wrapper > div {
      margin: 0 0 2em;
    }

  }

  .field-type-file,
  .field-type-image {

    table {

      td {
        padding-top: 10px;
        padding-bottom: 10px;

        &:first-child {
          padding-left: 10px;
        }

        &:last-child {
          text-align: right;
          padding-right: 10px;

          input {
            font-size: 14px;
            padding: 5px;
          }

        }
      }
    }

  }

  .form-type-managed-file {

    > label {
      margin-bottom: 7px;
    }

    .file-widget,
    .image-widget {
      margin-bottom: 5px;

      input {
        font-size: 14px;
        padding: 5px;
      }

    }

  }

  .field-type-datetime {

    fieldset {
      margin: 0 0 2em;
    }

    .date-no-float {
      width: 100%;
    }

    div.form-type-date-popup {
      margin: 0;
    }

    .date-padding > .form-item {
      margin-bottom: 1em;
    }

  }

  .filter-wrapper {
    padding: 0;
  }

  #edit-comment {

    > div {
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }

    }
  }

  .field-type-geofield {

    label {
      font-size: 16px;
      margin-bottom: 5px;
    }

    .geofield-gmap-cnt {
      width: 100%;
      height: 300px;
      margin-bottom: 1em;
    }

    .geofield-gmap-search {
      width: 100%;
      font-size: 14px;
      padding: 5px;
      margin-bottom: 1em;
    }

    .geofield-gmap-buttons {

      button {
        font-size: 14px;
        padding: 5px;
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }

      }
    }

  }

  .field-name-field-event-address {

    select {
      width: 100%;
      font-size: 14px;
      padding: 5px;
    }

    div.addressfield-container-inline > div.form-item {
      float: none;
      margin-right: 0;
    }

  }

  .vertical-tabs {

    .form-type-select label {
      margin-bottom: 7px;
    }

    select {
      font-size: 14px;
      padding: 5px;
    }

  }

}
