.block-ct-event {
  padding: 0 24px;

  @include breakpoint (576px) {
    padding: 0;
  }

  .header-info {
    text-align: center;
    padding: 0 6px;
    margin-bottom: 22px;

    a {
      display: inline-block;
      vertical-align: middle;

      &:not(.prev):not(.next) {
        color: $color-red-1;
        font-size: 20px;
        font-weight: bold;
        line-height: .5;
      }

      &.prev,
      &.next {
        width: 23px;
        height: 23px;
        text-indent: -10000em;

      }

      &.prev {
        background: url(image-url("icons/pager-check.svg")) no-repeat top center;
        background-size: contain;
        float: left;
      }

      &.next {
        background: url(image-url("icons/pager-check.svg")) no-repeat top center;
        background-size: contain;
        transform: rotate(180deg);
        float: right;
      }

    }

  }

  table {
    width: 100%;
    clear: both;

    tbody {
      border-top: none;
    }

    td {
      text-align: center;
      box-sizing: border-box;
      padding: 5px 0;

      &.weekend time {
        color: #B2B2B2;
      }

      &.today time {
        font-weight: 900;
      }

      &.has-event:not(.selected) {

        a {
          color: $color-red-1;
          font-weight: 900;

          &:after {
            content: "";
            display: inline-block;
            background: $color-red-1;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            position: absolute;
            top: 2px;
            right: 0;
          }

        }

      }

      &.selected {

        time {
          color: $color-red-1;
          font-weight: 900;
          border-color: $color-red-1;
          padding: 0 2px;
        }

      }

      a {
        display: inline-block;
        position: relative;
      }

      time {
        display: inline-block;
        width: 28px;
        height: 27px;
        border: 3px solid transparent;
      }

    }
  }

}
