// Border right for webpack menu
.page-webpack,
.node-type-webpack-project {
  .col-xl-3 {
    border-right: 1px solid $color-grey-2;
  }

  #main-content .region-container-content {
    margin-left: -15px;
    margin-right: -15px;
  }

  > .container {
    .region-container-content {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}

.webpack-menu {
  padding: 2px 5px 2px 0;

  a {
    font-size: 20px;
    font-family: $font-exo2;
    font-weight: 500;
    text-align: center;
    color: $color-grey-2;
    cursor: default;

    &.active {
      color: $color-grey-1;
      cursor: pointer;
    }
  }

  .ccaa {
    text-align: center;

    a {
      display: block;
      text-align: left;
    }

    img {
      width: auto;
      height: 100px;
      padding: 20px 0 20px 0;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;
    padding-right: 30px;

    li {

      a {
        font-size: 22px;
        text-align: left;
        color: $color-grey-2;

        &.active {
          color: $color-grey-1;
        }

        span {
          float: right;
        }
      }

      p {
        color: $color-red-4;
        opacity: 0.4;
        padding-left: 30px;
      }

      &.confirm {
        p {
          color: $color-red-4;
          opacity: 1;
        }
      }
    }
  }
}
