body.sm-is-front {

  &.logged-in {

    #block-sm-custom-blocks-main-sm-menu-contents {

      + div .menu-links {

        @include breakpoint (1215px) {
          margin-right: -8px;
        }

      }

      &.open + div .menu-links {

        @include breakpoint (1215px) {
          margin-right: -8px;
        }

      }
    }

  }

  &.not-logged-in {

    #main-content {

      .region-container-header {

        .region.region-header {

          #block-blockify-blockify-logo {
            z-index: 1;
            position: relative;
          }

          .menu-links {

            @include breakpoint (1215px) {
              margin-right: 0;
            }

          }
        }

      }

      .region-container-content {
        margin-left: -15px;
        margin-right: -15px;
      }

    }
  }

}