@import "buttom.scss";

.buttonBack {
  padding-top: 40px;
  position: relative;
}

.back-link {
  display: inline-block;
  @include btn-red-border;
  position: absolute;
  top: 0;
  left: 0;
}
