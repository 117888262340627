.paragraphs-item-slider-style-5 {

  .field-name-field-field-slider-style-5-title {
    font-size: 36px;
    font-weight: 300;
    line-height: 1;
    text-align: center;
    margin-bottom: 25px;

    @include breakpoint ($desk) {
      font-size: 48px;
      margin-bottom: 82px;
    }

  }

  .slick-slide {
    outline: none;
  }

  .field-name-field-slider-style-5-slides {
    position: relative;
    padding-bottom: 20px;

    .paragraphs-item-slider-style-5-slides {

      .content {

        > div {

          @include breakpoint ($tab) {
            width: 50%;
            float: left;
          }

        }

        .field-name-field-slider-style-5-slide-video {
          padding-bottom: 20px;

          @include breakpoint ($tab) {
            padding-top: 6px;
            padding-right: 20px;
            padding-bottom: 0;
          }

          @include breakpoint ($desk) {
            padding-right: 31px;
          }

          a {
            display: block;
            position: relative;

            &:hover:after {
              opacity: .7;
            }

            &:after {
              content: '';
              display: inline-block;
              width: 80px;
              height: 80px;
              background: url(image-url("icons/play.svg")) no-repeat center;
              transform: translate(-50%, -50%);
              transition: all 250ms;
              position: absolute;
              top: 50%;
              left: 50%;

              @include breakpoint ($tab) {
                width: 120px;
                height: 120px;
              }

            }

          }

          img {
            width: 100%;
            height: auto;
          }

        }

        .field-name-field-slider-style-5-slide-cont {

          @include breakpoint ($tab) {
            padding-left: 10px;
            padding-right: 10px;
          }

          @include breakpoint ($desk) {
            padding-left: 50px;
            padding-right: 15px;
          }

        }

        h1, h2, h3, h4, h5, h6 {
          font-weight: inherit;
          text-transform: uppercase;
          margin: 0;
        }

        p {
          font-family: $font-exo2;
        }

      }
    }

    .slider-nav-wrapper {
      transform: translate(-50%, 0);
      position: absolute;
      bottom: 0;
      left: 50%;

      ul {
        padding: 0;

        li {
          margin-right: 13px;

          &:last-child {
            margin-right: 0;
          }

        }

      }

      .slick-active button {
        background: $color-grey-1;
      }

      button {
        width: 15px;
        height: 15px;
        background: $color-grey-3;
        border-radius: 50%;
      }

    }
  }

}
