div[id^="block-sm-custom-blocks-footer-logos"] {

  .footer-logos {
    display: table;
    width: 100%;
    max-width: 134px;

    @include breakpoint ($desk) {
      max-width: 150px;
    }

    > div {
      display: table-cell;
      vertical-align: middle;
    }

    a {
      display: block;
    }

  }

  .logo-sm {
    padding-left: 8px;

    a {
      width: 100%;
      margin-top: 11px;
      //background: url(image-url("logo-white.png")) no-repeat center;
      //background-size: contain;

      @include breakpoint ($desk) {
        width: 150px;
        height: auto;
      }

    }

    span {
      display: none;
    }

  }

}

// Change footer logo only in Cruilla site.
.domain-cruilla div[id^="block-sm-custom-blocks-footer-logos"] .logo-sm a {
  width: 110px;
  height: 53px;
  //background: url(image-url("sm_cruilla_logo_white.png")) no-repeat center;
  background-size: contain;
}
