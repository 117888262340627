.paragraphs-item-slider-style-8 {
  padding: 40px 0;

  &.slides-qty-1 {

    .slick-slider .slick-list {
      transform: none;
      padding: 0 !important;

      > div {
        transform: none !important;
      }

    }

  }

  &.slides-qty-2 {

    .slick-slider .slick-list {

      @include breakpoint ($desk) {
        transform: none;
        padding: 0 !important;
      }

      > div {

        @include breakpoint ($desk) {
          transform: none !important;
        }

      }
    }

  }

  @include breakpoint ($desk) {
    padding: 110px 0;
  }

  .field-name-field-slider-style-8-text {
    padding: 0 5%;
    margin-bottom: 40px;

    @include breakpoint ($desk) {
      padding: 0 18%;
      margin-bottom: 100px;
    }

    h1, h2, h3, h4, h5, h6 {
      font-weight: inherit;
      margin: 0 0 .4em;

      @include breakpoint ($desk) {
        margin: 0 0 1em;
      }

    }

    p {
      font-size: 16px;
      font-family: $font-exo2;
      line-height: 1.4;
      margin: 0 0 .4em;

      @include breakpoint ($desk) {
        font-size: 20px;
        font-family: $font-exo2;
        line-height: 1.6;
        margin: 0 0 1em;
      }

      &:last-child {
        margin-bottom: 0;
      }

    }

  }

  .paragraphs-items-field-slider-style-8-slides {
    margin-bottom: 60px;
    position: relative;

    .slick-slide {
      outline: none;
      padding: 0 10px;

      .field-name-field-slider-style-8-slide-img,
      .field-name-field-slider-style-8-slide-video {
        margin-bottom: 20px;

        @include breakpoint ($desk) {
          margin-bottom: 40px;
        }

        img {
          width: 100%;
          height: auto;
        }

      }

      .field-name-field-slider-style-8-slide-video {

        a {
          display: block;
          position: relative;

          &:before {
            content: "";
            display: inline-block;
            width: 90px;
            height: 90px;
            background: url(image-url("icons/play.svg")) no-repeat center;
            background-size: contain;
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 50%;
          }

        }

      }

      h1, h2, h3, h4, h5, h6 {
        text-transform: uppercase;
        font-weight: inherit;
        margin: 0 0 .4em;

        @include breakpoint ($desk) {
          margin: 0 0 1em;
        }

      }

      p {
        font-family: $font-exo2;
        margin: 0 0 .4em;

        @include breakpoint ($desk) {
          margin: 0 0 1em;
        }

        &:last-child {
          margin-bottom: 0;
        }

      }

    }

    .slider-nav-wrapper {
      width: 100%;
      text-align: center;
      position: absolute;
      left: 0;
      bottom: -50px;

    }

    .slick-dots {
      padding: 0;

      li {

        &.slick-active {

          button {
            background: $color-red-3;
          }

        }

        button {
          background: transparent;
          border: 2px solid $color-red-3;
          border-radius: 50%;
        }

      }
    }

  }

  .field-name-field-slider-style-8-cta {
    text-align: center;
    margin-top: 40px;

    @include breakpoint ($desk) {
      margin-top: 135px;
    }

    a {
      display: inline-block;
      color: white;
      font-weight: 600;
      font-size: 14px;
      background: $color-red-3;
      border-radius: 3px;
      text-transform: uppercase;
      padding: 13px 20px 12px;

      @include breakpoint ($desk) {
        font-size: 16px;
        padding: 20px 40px 21px;
      }

    }
  }

}
