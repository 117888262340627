.paragraphs-item-generic-content {
  text-align: center;
  padding: 40px 10%;

  @include breakpoint ($desk) {
    padding: 120px 10%;
  }

  .field-name-field-gen-cont-icon {

    img {
      display: inline-block;
      max-width: 126px;
      height: auto;
      margin-bottom: 20px;

      @include breakpoint ($desk) {
        margin-bottom: 50px;
      }

    }

  }

  .field-name-field-gen-cont-content {
    max-width: 710px;
    margin: 0 auto;

    h1, h2, h3, h4, h5, h6 {
      font-weight: inherit;
      margin: 0 0 .5em;

      @include breakpoint ($desk) {
        margin: 0 0 1em;
      }

      &:last-child {
        margin-bottom: 0;
      }

    }

    p {
      font-family: $font-exo2;
      margin: 0 0 1em;

      &:last-child {
        margin-bottom: 0;
      }

    }

  }

  > a {
    display: inline-block;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border-radius: 3px;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 20px 11px;
    margin-top: 30px;

    @include breakpoint ($desk) {
      padding: 20px 40px 21px;
      margin-top: 70px;
    }

    &.bg-color-F3F2F3,
    &.bg-color-FFFFFF {
      color: black;
    }

    &:hover {
      opacity: .8;
    }

  }

}
