.page-books-catalog {
  .block-facetapi {
    .item-list {
      margin-left: 16px;

      li {
        &.facetapi-active-item {
          color: $color-grey-1;

          a.facetapi-active:before {
            background: $color-grey-1;
            border: $color-grey-1;
          }
        }

        a {
          color: $color-grey-1-5;
        }
      }
    }
  }
}
