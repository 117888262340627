.slick-dots {
  margin: 0;

  li {
    display: inline-block;
    list-style: none;
    margin-right: 13px;

    @include breakpoint ($desk) {
      margin-right: 24px;
    }

    &:last-child {
      margin-right: 0;
    }

    &.slick-active {

      button {
        background: url(image-url("icons/circle-white.svg")) no-repeat center transparent;
        background-size: 11px;
      }

    }

    button {
      display: block;
      width: 7px;
      height: 7px;
      background: url(image-url("icons/ring-white.svg")) no-repeat center transparent;
      background-size: contain;
      text-indent: -10000em;
      border: none;
      cursor: pointer;
      transition: all 250ms;
      outline: none;
      padding: 0;

      @include breakpoint ($desk) {
        width: 13px;
        height: 13px;
        background-size: 11px;
      }

      &:hover {
        opacity: .8;
        outline: none;
      }

    }

    button[aria-label="1 of 1"] {
      display: none;
    }

  }

}
