div[id^="block-sm-custom-blocks-footer-go-top"] {

  .go-top {
    display: block;
    width: 26px;
    height: 38px;
    background: url(image-url("icons/upload-arrow.svg")) no-repeat center;
    background-size: contain;

    span {
      display: none;
    }

  }

}
