body.admin-menu,
body.sm-role {
  font-size: 16px;
  line-height: inherit;

  @include breakpoint ($desk) {
    font-size: 19px;
  }

  .form-type-textarea label,
  .webform-component-textarea label,
  .form-type-mail input,
  .form-type-password input,
  .form-type-textfield input,
  .webform-component-textfield input,
  .webform-component-email input,
  a,
  .form-type-mail label,
  .form-type-password label,
  .form-type-textfield label,
  .webform-component-textfield label,
  .webform-component-email label {
    font-size: 16px;
    line-height: inherit;

    @include breakpoint ($desk) {
      font-size: 19px;
    }

  }

  .form-text.form-autocomplete [aria-live="assertive"] {
    font-size: 16px;
    line-height: 1.3;
  }

  .form-type-select {
    width: 100%;

    .selectize-dropdown, .selectize-input, .selectize-input input {
      font-size: 16px;
    }

  }

}

.field-type-markup {

  p a {
    font-size: inherit;
  }

}
