body.node-type-article {

  #block-blockify-blockify-page-title {
    color: $color-grey-1;
    margin-bottom: 10px;

    @include breakpoint ($desk) {
      padding-left: 20.5%;
      padding-right: 20.5%;
      margin-bottom: 16px;
    }

    h1 {
      font-size: 22px;
      font-weight: 700;
      line-height: 1.1;
      margin: 0;

      @include breakpoint ($desk) {
        font-size: 40px;
        font-weight: 600;
      }

    }
  }

}
