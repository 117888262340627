.paragraphs-item-slider-style-2 {
  position: relative;
  background: $color-grey-4;

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-slide {
    outline: none;
    padding: 20px 24px 22px;

    @include breakpoint ($desk) {
      padding: 35px 150px 32px;
    }

  }

  .slick-dots {
    text-align: center;
    padding: 0 0 20px;

    li {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }

    }

    .slick-active {

      button {
        background: $color-red-1;
      }

    }

    button {
      background: transparent;
      border: 2px solid $color-red-1;
      border-radius: 50%;
    }

  }

  .paragraphs-item-slider-style-2-slides {

    @include breakpoint ($desk) {
      display: flex;
      align-items: flex-start;
    }

    > .field-name-field-slider-style-2-slide-img {
      padding-left: 40px;
      padding-right: 40px;
      margin-bottom: 35px;

      @include breakpoint ($desk) {
        width: 40%;
        padding-left: 0;
        padding-right: 30px;
        margin-bottom: 0;
      }

      img {
        margin: 0 auto;
      }

    }

    > .content {

      @include breakpoint ($desk) {
        width: 60%;
        padding-top: 7px;
      }

      .field-name-field-slider-style-2-slide-cont {

        p {
          margin: 0;

          @include breakpoint ($desk) {
            font-weight: 300;
          }

        }

      }

      .field-name-field-slider-s2-see-more-link {
        text-align: right;
        margin-top: 15px;

        @include breakpoint ($desk) {
          margin-top: 19px;
        }

        a {
          color: $color-red-1;
          font-size: 16px;
          font-family: $font-fira-sanz-condensed;
          text-transform: uppercase;
          outline: none;

          @include breakpoint ($desk) {
            font-size: 20px;
            font-family: $font-exo2;
          }

          &:after {
            content: '';
            width: 11px;
            height: 15px;
            display: inline-block;
            background: url(image-url("icons/arrow-red-medium.svg")) no-repeat center;
            background-size: contain;
            margin-left: 4px;
            position: relative;
            top: 2px;

            @include breakpoint ($desk) {
              width: 23px;
              height: 20px;
              background: url(image-url("icons/arrow-red-fine.svg")) no-repeat center;
              background-size: contain;
              margin-left: 7px;
            }

          }

          &:hover {
            opacity: .8;
          }

        }
      }
    }

  }

  // Nav
  .slider-nav-wrapper {
    width: 100%;
    height: 0;
    position: absolute;
    top: 80px;

    @include breakpoint ($desk) {
      margin-top: -32px;
      top: 50%;
    }

    .slider-nav {
      width: 100%;

      .slick-prev {
        float: left;
        margin-left: 24px;

        @include breakpoint ($desk) {
          margin-left: 45px;
        }

      }

      .slick-next {
        float: right;
        margin-right: 24px;

        @include breakpoint ($desk) {
          margin-right: 45px;
        }

      }

      button {
        width: 16px;
        height: 27px;
        text-indent: -10000em;
        border: none;
        background: transparent;
        cursor: pointer;
        position: relative;
        transition: all 250ms;
        outline: none;
        padding: 0;

        @include breakpoint ($desk) {
          width: 38px;
          height: 65px;
        }

        &:hover {
          opacity: .7;
          outline: none;
        }

        &.slick-prev:before {
          transform: rotate(180deg);
        }

        &:before {
          content: "";
          display: inline-block;
          width: 100%;
          height: 100%;
          background-image: url(image-url("icons/chevron-red-fine.svg"));
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          top: 0;
          left: 0;

          @include breakpoint ($desk) {

          }

        }
      }
    }
  }

}
