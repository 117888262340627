.node--event--full {
  color: $color-grey-1;
  position: relative;

  .share-social-links {
    position: absolute;
    top: 0;
    right: 0;

    @include breakpoint ($desk) {
      top: 5px;
    }

  }

  .field-name-title {
    padding-right: 30px;
    margin-bottom: 10px;

    @include breakpoint ($desk) {
      width: 70%;
      padding-right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 16px;
    }

    h2 {
      font-size: 22px;
      font-weight: 700;
      line-height: 1.1;
      margin: 0;

      @include breakpoint ($desk) {
        font-size: 40px;
        font-weight: 600;
      }

    }

  }

  .field-name-field-event-subtitle {
    font-size: 16px;
    font-family: $font-fira-sanz-condensed;
    line-height: 1.2;
    margin-bottom: 31px;

    @include breakpoint ($desk) {
      font-size: 26px;
      line-height: 28px;
      width: 70%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 54px;
    }

  }

  .field-name-field-event-location {
    margin-left: -15px;
    margin-right: -15px;

    @include breakpoint ($desk) {
      padding: 0 13.5%;
      margin-left: 0;
      margin-right: 0;
    }

  }

  .group-info {
    font-size: 16px;
    font-family: $font-fira-sanz-condensed;
    background: $color-grey-4;
    padding: 20px;
    margin: 0 -15px 25px;

    @include breakpoint ($desk) {
      font-size: 22px;
      margin: 0 13.5% 45px;
    }

  }

  .field-name-field-event-address {
    $margin: 4px;
    margin-bottom: 10px;

    .field-item * {
      vertical-align: top;
    }

    .street-block {
      display: inline-block;
      margin-right: $margin;

      div {
        display: inline-block;
      }

      &:after {
        content: ',';
        display: inline-block;
      }

    }

    .addressfield-container-inline {
      display: inline-block;

      .locality {
        margin-right: $margin;

        &:after {
          content: ',';
          display: inline-block;
        }

      }

      .state {
        margin-right: $margin;

        &:after {
          content: ',';
          display: inline-block;
        }

      }
    }

    .country {
      display: inline-block;
    }

  }

  .field-name-field-event-date {

    .field-item > span {
      display: inline-block;
      text-transform: lowercase;

      &:first-letter {
        text-transform: uppercase;
      }

      span {
        text-transform: lowercase;
      }

    }

    .date-display-start {
      display: inline-block;
      margin-right: 7px;
    }

    .date-display-end {
      display: inline-block;
      margin-left: 7px;
    }

  }

  .field-name-field-event-body {
    margin-bottom: 20px;

    @include breakpoint ($desk) {
      margin-bottom: 50px;
    }

    .field-item > *:not(blockquote) {

      @include breakpoint ($desk) {
        width: 70% !important;
        margin-left: auto;
        margin-right: auto;
      }

    }

    .field-item > ul {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }

    img[data-picture-align="left"] {
      margin-right: 10px;
      margin-bottom: 10px;
    }

    img[data-picture-align="center"] {
      margin-right: 10px;
      margin-left: 10px;
      margin-bottom: 10px;
    }

    img[data-picture-align="right"] {
      margin-left: 10px;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      font-weight: 300;
      line-height: 17px;
      margin: 0 0 1em;

      @include breakpoint ($desk) {
        font-size: 21px;
        line-height: 24px;
      }

    }

    table {
      margin-top: 40px;
      margin-bottom: 40px;
    }

  }

  .field-name-field-event-image-gallery {
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 12px;

    @include breakpoint ($desk) {
      padding: 0 13.5%;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 19px;
    }

    // Controls
    .slider-nav-wrapper {
      width: 80%;
      padding-top: 4px;
      position: absolute;
      bottom: 1px;
      right: 12px;

      @include breakpoint ($desk) {
        width: 40%;
        right: 13.5%;
      }

      .slider-nav {
        float: right;
        padding-right: 1px;

        button {
          width: 27px;
          height: 27px;
          text-indent: -10000em;
          background-color: white;
          border: none;
          cursor: pointer;
          position: relative;
          transition: all 250ms;
          outline: none;
          margin-right: 2px;

          @include breakpoint ($desk) {
            width: 48px;
            height: 48px;
          }

          &:hover {
            opacity: .9;
            outline: none;
          }

          &:last-child {
            margin-right: 0;
          }

          &.slick-prev:before {
            transform: rotate(180deg);
          }

          &:before {
            content: "";
            display: inline-block;
            width: 100%;
            height: 100%;
            background-image: url(image-url("icons/chevron-red-fine.svg"));
            background-size: 7px;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            top: 0;
            left: 0;

            @include breakpoint ($desk) {
              background-size: 13px;
            }

          }

        }

      }

      .slick-dots {
        float: right;
        padding-top: 3px;
        padding-left: 0;
        padding-right: 14px;

        @include breakpoint ($desk) {
          padding-top: 14px;
          padding-right: 24px;
        }

      }
    }

  }

  .field-name-field-event-gallery-footer {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;

    @include breakpoint ($desk) {
      padding: 0 13.5%;
      margin-bottom: 50px;
    }

    p {
      margin: 0;
    }

  }

  .field-name-field-event-category {
    border-top: 1px solid $color-grey-1;
    padding-top: 5px;
    padding-left: 6px;
    padding-right: 6px;
    margin: 30px 7% 20px;

    @include breakpoint ($desk) {
      padding-top: 11px;
      margin: 0 7% 100px;
    }

    .field-item {
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      margin-right: 12px;

      @include breakpoint ($desk) {
        font-size: 16px;
        font-weight: 700;
      }

      &:before {
        content: '#';
        display: inline-block;
      }

      &:last-child {
        margin-right: 0;
      }

    }

    a {
      display: inline-block;
      font-size: 14px;
      font-weight: 400;

      @include breakpoint ($desk) {
        font-size: 16px;
        font-weight: 700;
      }

      &:hover {
        color: $color-red-1;
      }

    }
  }

}
