.view-general-search {

  .view-content {
    margin-bottom: 35px;

    .views-row {
      border-bottom: 1px solid #D2D2D2;
      padding-top: 13px;
      padding-bottom: 13px;

      @include breakpoint ($tab) {
        padding-top: 20px;
        padding-bottom: 18px;
      }

      &:first-child {
        padding-top: 0;

        @include breakpoint ($tab) {
          padding-top: 20px;
        }

      }

      &:last-child {
        border-bottom: none;
      }

    }
  }

  .item-list {

    .pager {
      margin-top: 0;
      margin-bottom: 50px;
    }

  }
}
