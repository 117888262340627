#main-content {
  $paddingWidth: 75px;

  .region-container-header {

    .region.region-header {
      @include clearfix;
      min-height: 53px;
      margin-right: -15px;

      @include breakpoint ($desk) {
        margin-right: -8px;
      }

      #block-blockify-blockify-logo {
        float: left;
      }

      .menu-links {
        float: right;
        z-index: 20;
      }

    }

  }

  .region-container-pre-content {
    margin-bottom: 14px;
  }

  .region-container-content {

    @include breakpoint($tab) {
      margin-left: 20px;
      margin-right: 20px;
    }

    @include breakpoint($desk) {
      margin-left: $paddingWidth;
      margin-right: $paddingWidth;
    }

    .region-sidebar-left {
      margin-bottom: 1.5em;

      > .inner-content {
        box-shadow: 0 3px 9px 0 rgba(50, 50, 50, 0.2);
        border-radius: 24px;
        padding: 20px;
      }

    }

    .region.region-content {
      overflow: visible;
    }

  }

  .region-container-footer {
    margin-bottom: 0;

    .region.region-footer {
      background-color: $color-grey-1;
      margin-left: -15px;
      margin-right: -15px;
      padding: 27px 26px 19px;

      @include breakpoint ($tab) {
        padding: 32px 40px 31px;
      }

      @include breakpoint ($desk) {
        display: flex;
        padding: 40px 63px 43px;
      }

      @include breakpoint ($desk-lg) {
        margin-left: 0;
        margin-right: 0;
      }

      > * {

        @include breakpoint ($desk) {
          display: inline-block;
        }

      }

      .footer-logos {

        @include breakpoint ($desk) {
          width: 22%;
          align-self: center;
        }

      }

      div[id^="block-sm-custom-blocks-footer-logos"] {
        width: 50%;
        float: left;

        @include breakpoint ($desk) {
          width: 100%;
          float: none;
        }

      }

      .footer-menu {

        @include breakpoint ($desk) {
          width: 20%;
          align-self: flex-start;
        }

        &.only-desktop {
          @include breakpoint ($desk) {
            display: block !important;
          }
        }

      }

      div[id^="block-menu-menu-footer-menu"] {

      }

      .footer-menu-legals {
        clear: both;
        padding-top: 14px;

        @include breakpoint ($desk) {
          padding-top: 0;
        }

      }

      .footer-legals {

        @include breakpoint ($desk) {
          width: 33.4%;
          align-self: flex-start;
        }

        &.only-desktop {
          @include breakpoint ($desk) {
            display: block !important;
          }
        }

      }

      .footer-social {

        @include breakpoint ($desk) {
          width: 20.5%;
          align-self: center;
        }

      }

      div[id^="block-sm-custom-blocks-footer-social-links"] {
        width: 50%;
        float: right;
        text-align: right;
        margin-top: 11px;

        @include breakpoint ($desk) {
          width: 100%;
          float: none;
          text-align: initial;
          margin-top: 0;
        }

      }

      .footer-go-top {

        @include breakpoint ($desk) {
          align-self: center;
        }

        &.only-desktop {
          @include breakpoint ($desk) {
            display: block !important;
          }

        }
      }

      .footer-site-info-wrapper {

        .footer-social-wrapper {
          display: flex;

          .footer-social {
            flex: 1 auto;
          }

        }

        .footer-site-information {
          color: white;
          border-left: 1px solid white;
          padding-left: 12px;
        }

      }
    }
  }

}

// Para usuarios logueados tenemos alguna que otra preferencia en estilos.
body.logged-in {

  &.front {

    #main-content {
      padding-top: 60px;

      .region-container-header .region.region-header {

        @include breakpoint ($desk) {
          margin-right: 0;
        }

      }
    }

  }

  #main-content {

    .region-container-content {
      margin-left: -15px;
      margin-right: -15px;
    }

  }

}

body:not(.logged-in) {

  .region-container-pre-content {

    @include breakpoint($tab) {
      margin-left: 20px;
      margin-right: 20px;
    }

    @include breakpoint($desk) {
      margin-left: 75px;
      margin-right: 75px;
    }

  }

}

body.front {

  &:not(.logged-in) {

    #main-content {

      .region-container-header {

        .region.region-header {

          @include breakpoint ($tab) {
            margin-right: -15px;
          }

        }
      }
    }

  }

  #main-content {

    .region-container-header {

      @include breakpoint ($tab) {
        margin-left: -15px;
      }

      @include breakpoint ($desk) {
        margin-left: 0;
      }

      .region.region-header {

        @include breakpoint ($tab) {
          margin-right: 0;
        }

        @include breakpoint ($desk) {
          margin-right: -15px;
        }

      }

    }

    .messages {
      margin-top: 58px;

      @include breakpoint ($tab) {
        margin-top: 59px;
      }

      @include breakpoint ($desk) {
        margin-top: 83px;
      }

    }
  }

}

// Menos en la home, reservamos espacio para el menu fixed.
body:not(.front) {

  #main-content {

    @include breakpoint ($desk) {
      padding-top: 83px;
    }

    .messages {
      margin-bottom: 30px;
    }

  }

}
