.node--event--ct-as-landing-page-component {

  .content {

    .left {
      width: 70px;
      float: left;
      box-sizing: border-box;
      padding-top: 5px;
      padding-left: 7px;

      .date-container {
        border: 4px solid $color-red-1;
        padding: 3px 5px 6px;

        span {
          display: block;
          color: $color-red-1;
          font-size: 22px;
          font-weight: 700;
          line-height: 23px;
          text-transform: uppercase;
          text-align: center;
        }

      }

    }

    .right {
      width: calc(100% - 70px);
      float: right;
      padding-left: 25px;

      h2 {
        color: $color-grey-1;
        font-size: 20px;
        font-family: $font-fira-sanz-condensed;
        font-weight: 400;
        margin: 0 0 1px;
      }

      .province {
        display: inline-block;
        color: $color-red-1;
        font-size: 14px;
        line-height: 25px;
        font-weight: 700;
        text-transform: uppercase;
        float: left;
        position: relative;
        padding-left: 22px;

        &:before {
          content: "";
          display: inline-block;
          width: 15px;
          height: 17px;
          background: url(image-url("icons/checkmark-red.svg")) no-repeat center;
          background-size: contain;
          transform: translate(0, -50%);
          position: absolute;
          top: 50%;
          left: 0;
        }

      }

      a {
        display: inline-block;
        color: $color-red-1;
        font-size: 14px;
        line-height: 25px;
        font-weight: 700;
        text-transform: uppercase;
        float: right;
        position: relative;
        padding-right: 17px;

        &:hover {
          opacity: .8;
        }

        &:before {
          content: "";
          display: inline-block;
          width: 12px;
          height: 15px;
          background: url(image-url("icons/arrow-red-medium.svg")) no-repeat center;
          background-size: contain;
          transform: translate(0, -50%);
          position: absolute;
          top: 50%;
          right: 0;
        }

      }
    }
  }

}
