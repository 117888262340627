.sm-search-books-block {
  background: $color-grey-4;
  padding: 0 10px;
  margin-bottom: 20px;

  // We need use custom breakpoint.-
  $custom-br: 430px;

  @include breakpoint ($desk) {
    margin-bottom: 50px;
  }

  .wrapper {
    max-width: 640px;
    padding: 22px 0 40px;
    margin: 0 auto;

    > h2 {
      font-size: 28px;
      font-weight: bold;
      margin: 0 0 22px;
    }

    form {

      > div {
        display: flex;
        flex-wrap: wrap;

        @include breakpoint ($custom-br) {
          flex-wrap: nowrap;
        }

      }

      .form-item {
        width: calc(100% - 55px);
        order: 1;
        margin: 0;

        @include breakpoint ($custom-br) {
          width: auto;
        }

        &.form-type-select {
          min-width: 110px;
          width: 100%;
          order: 0;
          margin-bottom: 7px;

          @include breakpoint ($custom-br) {
            width: auto;
            margin-bottom: 0;
          }

        }

        input {
          height: 100%;
          line-height: 15px;
          border: none;
          padding: 9px 10px;
        }

        .selectize-control.single {
          height: 100%;
          font-size: 12px;

          > .selectize-input {
            height: 100%;
            display: flex;
            align-items: center;
            color: #000;
            font-size: 12px;
            background: $color-grey-3;
            border: none;
            box-shadow: none;
            padding: 10px 35px 10px 15px;

            @include breakpoint ($custom-br) {
              border-radius: 4px 0 0 4px;
            }

            &.input-active {

              &:after {
                transform: rotate(0deg);
              }

            }

            > input {
              color: #000;
              font-size: 14px;

              * {
                color: #000;
              }

            }

            input::placeholder,
            input::-webkit-input-placeholder,
            input::-moz-placeholder,
            input:-ms-input-placeholder,
            input:-moz-placeholder {
              color: #000;
            }

            &:after {
              width: 13px;
              height: 10px;
              background: url(image-url("icons/chevron-black-bold.svg")) no-repeat center;
              background-size: contain;
              transform: translate(0, 0) rotate(180deg);
              border: none;
              transition: all .4s;
              margin-top: -5px;
              left: calc(100% - 25px);
            }

          }
        }

      }

      .form-submit {
        width: 55px;
        background: url(image-url("icons/icon-magnified-white.svg")) no-repeat center $color-red-1;
        background-size: 30px;
        line-height: 1;
        text-indent: -1000em;
        border: none;
        outline: none;
        overflow: hidden;
        order: 2;
        cursor: pointer;
        transition: all .4s;
        padding: 7px 10px 8px;

        @include breakpoint ($custom-br) {
          border-radius: 0 4px 4px 0;
        }

        &:hover, &:focus {
          background-color: $color-red-3;
        }

      }
    }
  }

}
