#edit-scheduler-settings {

  .fieldset-wrapper > div {

    &:first-child {
      margin-bottom: 20px;
    }

    label {
      display: block;
      margin-bottom: 3px;
    }

  }

}
