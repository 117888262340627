.node--book--search-result {

  .wrapper {
    display: flex;

    .left {
      width: 100px;
      padding-top: 20px;
      padding-right: 10px;
      text-align: center;
    }

    .right {
      width: calc(100% - 105px);

      .subtitle {
        color: $color-grey-1-5;
        font-size: 14px;
        font-weight: 500;
        font-family: $font-fira-sanz-condensed;
        text-transform: uppercase;
        margin: 0 0 7px;
      }

      .title {
        margin: 0 0 5px;
        font-size: 0;

        a {
          font-size: 24px;
          font-family: $font-exo2;
        }
      }

      .field-name-field-book-synopsis {
        color: $color-grey-1-5;
        font-size: 14px;
        font-weight: 400;

        p {
          margin: 0;
        }

      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4px;

        .price {
          font-size: 14px;
          font-weight: 500;
          padding-top: 3px;
        }

        .read-more {
          display: inline-block;
          color: $color-red-1;
          font-size: 16px;
          font-family: $font-exo2;
          font-weight: bold;
          text-transform: uppercase;
          position: relative;
          padding-right: 16px;

          &:hover {
            opacity: .7;
          }

          &:after {
            content: "";
            display: inline-block;
            width: 12px;
            height: 15px;
            background: url(image-url("icons/arrow-red-medium.svg")) no-repeat center;
            background-size: contain;
            transform: translate(0, -50%);
            position: absolute;
            top: 50%;
            right: 0;
          }

        }
      }
    }
  }

}
