@mixin flex-grid($cols, $gutter: 0, $child: '> *') {
  display: flex;
  flex-wrap: wrap;

  #{$child} {
    // Reset to prevent external styles break the grid.
    margin-left: 0;
    margin-bottom: 0;
    $item-width: calc((100% / #{$cols}) - #{$gutter} + (#{$gutter} / #{$cols}));
    margin-right: $gutter;
    width: $item-width;
    box-sizing: border-box;

    // We add the gutter for all items except the first row.
    &:nth-child(n+#{$cols + 1}) {
      margin-top: $gutter;
    }

    // When we reach the cols per line, the last
    // col item must not have margin right.
    &:nth-child(#{$cols}n) {
      margin-right: 0;
    }

  }
}
