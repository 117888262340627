.node--news--full {
  color: $color-grey-1;
  padding-top: 7px;
  position: relative;

  .share-social-links {
    position: absolute;
    top: 5px;
    right: 0;
  }

  @include breakpoint ($desk) {
    padding-top: 0;
  }

  .field-name-field-news-date {
    padding-right: 30px;
    margin-bottom: 13px;

    @include breakpoint ($desk) {
      padding-right: 0;
      margin-bottom: 32px;
    }

  }

  .field-name-title {
    margin-bottom: 10px;

    @include breakpoint ($desk) {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 17px;
    }

    h2 {
      font-size: 22px;
      font-weight: 700;
      line-height: 1.1;
      margin: 0;

      @include breakpoint ($desk) {
        font-size: 35px;
        font-weight: 600;
      }

    }

  }

  .field-name-field-news-subtitle {
    font-size: 16px;
    font-family: $font-fira-sanz-condensed;
    line-height: 1.2;
    margin-bottom: 31px;

    @include breakpoint ($desk) {
      font-size: 26px;
      line-height: 28px;
      width: 70%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 54px;
    }

  }

  .field-name-field-news-image-gallery {
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 12px;

    @include breakpoint ($desk) {
      padding: 0 13.5%;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 19px;
    }

    // Controls
    .slider-nav-wrapper {
      width: 80%;
      padding-top: 4px;
      position: absolute;
      bottom: 1px;
      right: 12px;

      @include breakpoint ($desk) {
        width: 40%;
        right: 13.5%;
      }

      .slider-nav {
        float: right;
        padding-right: 1px;

        button {
          width: 27px;
          height: 27px;
          text-indent: -10000em;
          background-color: white;
          border: none;
          cursor: pointer;
          position: relative;
          transition: all 250ms;
          outline: none;
          margin-right: 2px;

          @include breakpoint ($desk) {
            width: 48px;
            height: 48px;
          }

          &:hover {
            opacity: .9;
            outline: none;
          }

          &:last-child {
            margin-right: 0;
          }

          &.slick-prev:before {
            transform: rotate(180deg);
          }

          &:before {
            content: "";
            display: inline-block;
            width: 100%;
            height: 100%;
            background-image: url(image-url("icons/chevron-red-fine.svg"));
            background-size: 7px;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            top: 0;
            left: 0;

            @include breakpoint ($desk) {
              background-size: 13px;
            }

          }
        }

      }

      .slick-dots {
        float: right;
        padding-top: 3px;
        padding-left: 0;
        padding-right: 14px;

        @include breakpoint ($desk) {
          padding-top: 14px;
          padding-right: 24px;
        }

      }
    }

  }

  .field-name-field-news-gallery-footer {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;

    @include breakpoint ($desk) {
      padding: 0 13.5%;
      margin-bottom: 50px;
    }

    p {
      margin: 0;
    }

  }

  .field-name-field-news-body {
    margin-bottom: 20px;

    @include breakpoint ($desk) {
      margin-bottom: 50px;
    }

    .field-item > *:not(blockquote) {

      @include breakpoint ($desk) {
        width: 70%;
        margin-left: auto !important;
        margin-right: auto !important;
      }

    }

    .field-item > ul {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }

    img[data-picture-align="left"] {
      margin-right: 10px;
      margin-bottom: 10px;
    }

    img[data-picture-align="center"] {
      margin-right: 10px;
      margin-left: 10px;
      margin-bottom: 10px;
    }

    img[data-picture-align="right"] {
      margin-left: 10px;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      font-weight: 300;
      line-height: 17px;
      margin: 0 0 1em;

      @include breakpoint ($desk) {
        font-size: 21px;
        line-height: 24px;
      }

    }

  }

  .field-name-field-news-document {
    margin-bottom: 30px;

    @include breakpoint ($desk) {
      padding: 0 13.5%;
      margin-bottom: 50px;
    }

    .field-item {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      img {
        display: none;
      }

      a {
        display: inline-block;
        color: $color-red-1;
        font-size: 14px;
        font-family: $font-fira-sanz-condensed;
        position: relative;
        padding-right: 15px;

        @include breakpoint ($desk) {
          font-size: 20px;
          padding-right: 25px;
        }

        &:hover {
          opacity: .7;
        }

        &:after {
          content: "";
          display: inline-block;
          width: 11px;
          height: 12px;
          background: url(image-url("icons/upload-arrow-red-bold.svg")) no-repeat center;
          background-size: contain;
          transform: translate(0, -50%) rotate(180deg);
          position: absolute;
          top: 50%;
          right: 0;

          @include breakpoint ($desk) {
            width: 14px;
            height: 16px;
          }

        }
      }
    }

  }

  .field-name-field-news-category {
    border-top: 1px solid $color-grey-1;
    padding-top: 5px;
    padding-left: 6px;
    padding-right: 6px;
    margin: 0 0 40px;

    @include breakpoint ($desk) {
      padding-top: 11px;
      margin: 0 7% 100px;
    }

    .field-item {
      display: inline-block;
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }

    }

    a {
      display: inline-block;
      font-size: 14px;
      font-weight: 400;

      @include breakpoint ($desk) {
        font-size: 16px;
        font-weight: 700;
      }

      &:before {
        content: '#';
        display: inline-block;
      }

      &:hover {
        color: $color-red-1;
      }

    }
  }

}
