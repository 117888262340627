.paragraphs-item-products-by-category {
  background: $color-grey-4;
  padding: 4px 14px 14px;

  @include breakpoint ($desk) {
    padding: 35px 45px;
  }

  // Tabs
  .headline {
    margin-left: -9px;
    margin-bottom: 23px;

    @include breakpoint ($desk) {
      margin-left: -15;
      margin-bottom: 47px;
    }

    .tabs {
      float: left;
      padding: 10px 0 0;
      margin: 0;

      @include breakpoint ($desk) {
        max-width: 80%;
      }

      @include breakpoint ($desk-lg) {
        max-width: initial;
      }

      li {
        display: inline-block;
        float: left;
        margin-bottom: 7px;

        &:first-child a {
          color: $color-red-1;
          font-weight: 900;
          margin-bottom: 0;
        }

        &:last-child a {
          border-right: none;
        }

        a {
          color: $color-grey-1;
          font-size: 13px;
          font-weight: 400;
          text-transform: uppercase;
          border-right: 2px solid #9d9d9d;
          padding: 1px 9px 3px;

          @include breakpoint ($desk) {
            font-size: 20px;
            padding: 1px 15px 6px;
          }

        }
      }

    }

    > a {
      display: none;
      color: white;
      font-size: 18px;
      font-family: $font-fira-sanz-condensed;
      font-weight: 500;
      text-transform: uppercase;
      background: $color-red-1;
      float: right;
      padding: 13px 15px 12px;

      @include breakpoint ($desk) {
        display: inline-block;
      }

      &:hover {
        opacity: .8;
      }

    }

  }

  // Slider
  .products-by-category--products-list {
    clear: both;

    .product-items {
      padding: 0;
      margin-bottom: 12px;

      @include breakpoint ($desk) {
        margin-bottom: 28px;
      }

      li {
        list-style: none;

        &.slide-group {

          > ul {
            margin: 0;
            padding: 0;
            list-style: none;

            > li {
              margin: 0;
              padding: 0;
            }
          }

          &.group-of-4 {
            > ul {
              @include clearfix;

              > li {
                width: 50%;
                float: left;
                padding-bottom: 14px;

                &:nth-child(odd) {
                  padding-right: 7px;
                }

                &:nth-child(even) {
                  padding-left: 7px;
                }

              }

            }

          }

          &.group-of-5 {
            > ul {
              @include clearfix;
              margin: 0 -13px;

              > li {
                width: 20%;
                float: left;
                padding: 0 13px;

              }

            }
          }
        }
      }
    }

  }

  // Pager
  .slider-nav {
    text-align: right;

    > div {
      display: inline-block;
    }

    .more-wrapper {
      float: left;
      padding-top: 4px;

      @include breakpoint ($desk) {
        display: none;
      }

      a {
        color: white;
        font-size: 12px;
        font-family: $font-fira-sanz-condensed;
        font-weight: 500;
        text-transform: uppercase;
        background: $color-red-1;
        padding: 10px 11px 8px;

        &:hover {
          opacity: .8;
        }
      }

    }

    .slick-dots-wrapper {
      margin-right: 7px;

      @include breakpoint ($desk) {
        margin-right: 44px;
      }

      .slick-dots {
        padding-left: 0;
        position: relative;
        top: 4px;
      }

      li.slick-active button {
        background: none;
        border: 2px solid $color-red-1;
      }

      button {
        background: url(image-url("icons/circle-red.svg")) no-repeat center;
        border-radius: 50%;
        background-size: 11px;
        border: 2px solid transparent;
      }

    }

    .slick-arrows {

      button {
        width: 32px;
        height: 32px;
        text-indent: -10000em;
        border: none;
        cursor: pointer;
        position: relative;
        transition: all 250ms;
        outline: none;
        margin-right: 2px;

        @include breakpoint ($desk) {
          width: 49px;
          height: 49px;
        }

        &:hover {
          opacity: .8;
          outline: none;
        }

        &.slick-prev {
          background-color: white;

          &:before {
            background: url(image-url("icons/chevron-red-fine.svg")) no-repeat center;
            transform: rotate(180deg);
            background-size: 8px;

            @include breakpoint ($desk) {
              background-size: 13px;
            }

          }

        }

        &.slick-next {
          background-color: $color-red-1;
          margin-right: 0;

          &:before {
            background: url(image-url("icons/chevron-white-fine.png")) no-repeat center;
            background-size: 8px;
            margin-top: -1px;

            @include breakpoint ($desk) {
              background-size: 13px;
            }

          }

        }

        &:before {
          content: "";
          display: inline-block;
          width: 100%;
          height: 80%;
          position: absolute;
          top: 10%;
          left: 0;
        }

      }
    }
  }

}
