// This is a temporary hack asked by the client. They don't want to
// allow users switch to english but want to be able to keep
// internal translations available.
// @todo Remove this entire file when the situation gets normal again.
body {
  .language-switcher-locale-url {
    display: none !important;
  }
}

// This is a temporary hack asked by the client. They would like to remove
// the other language options.
// @todo Remove this entire file when the situations gets normal again.
[class*="translate"],
  [class*="language"],
  a[href*="translate"] {
  display: none !important;
}

// Temporary hack to set visible cookiefirst block.
[class*="cookiefirst-root"],
[class*="notranslate"] {
  display: unset !important;
}

[class*="cf2CeT"] {
  display: block !important;
}

// Temporary hack to set visible book language field.
[class*="book-language"] {
  display: block !important;
}
