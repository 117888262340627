.block-sm-submenu-block .content {

  ul {
    padding: 0;
    margin: 0;

    li {
      border-bottom: 1px solid $color-grey-2;
      list-style: none;
      padding: 0;
      margin: 0;

      &.active > span {

        .has-submenu.active {
          color: $color-red-1;
          font-weight: bold;
        }

        .has-submenu {
          color: $color-red-1;
          font-weight: bold;
        }

        > a.expand-button {
          transform: rotate(0deg);
        }

      }

      &:last-child {
        border-bottom: none;
      }

      .parent-link {
        display: block;
        position: relative;
      }

      a {
        display: inline-block;
        cursor: pointer;

        &.active {
          color: $color-red-1;
          font-weight: bold;
          position: relative;
          padding-left: 20px;

          &:after {
            content: "";
            display: inline-block;
            width: 14px;
            height: 14px;
            background: url(image-url("icons/arrow-red-medium.svg")) no-repeat center;
            background-size: contain;
            transform: translate(0, -50%);
            margin-top: -2px;
            position: absolute;
            top: 50%;
            left: 0;
          }

        }

        &.expand-button {
          width: 14px;
          height: 11px;
          background: url(image-url("icons/chevron-red-bold.svg")) no-repeat center;
          background-size: contain;
          transform: rotate(180deg);
          float: right;
          padding: 0;
          position: absolute;
          top: 12px;
          right: 12px;

          span {
            display: none;
          }

        }
      }
    }

  }

  // 1º Nivel
  > ul {

    > li {
      border-bottom: none;

      &.active {

        > ul {
          display: block;
        }

      }

      > a {
        display: none;
        padding-top: 6px;
        padding-right: 5px;
        padding-bottom: 13px;
      }

      > .parent-link {
        @include clearfix;
        display: none;
        padding-top: 6px;
        padding-bottom: 13px;

        > a {

          &.expand-button {
            margin-top: 6px;
          }

        }

      }

      // 2º Nivel
      > ul {
        display: none;

        > li {
          border-bottom: none;

          &.active {

            > ul {
              display: block;
            }

          }

          > a {
            display: none;
            font-size: 14px;
            font-weight: normal;
            padding: 7px 5px 7px 0;
          }

          > .parent-link {
            display: none;

            > a {
              font-size: 14px;
              font-weight: normal;
              padding: 7px 5px 7px 0;

              &.expand-button {
                margin-top: 11px;
              }

              &.active:after {
                margin-top: 0;
              }

            }

          }

          // 3º Nivel
          > ul {
            display: none;
            padding-left: 0;

            > li {

              &.active {

                > ul {
                  display: block;
                }

              }

              span {

                a {
                  padding-top: 6px;
                  padding-right: 5px;
                  padding-bottom: 13px;
                }

              }

              a {
                font-weight: 700;
                padding: 10px 5px 12px 0;
              }

              // 4º Nivel
              > ul {
                display: none;
                padding-left: 20px;

                > li {

                  &.active {

                    > ul {
                      display: block;
                    }

                  }

                  a {
                    font-size: 14px;
                    font-weight: 500;
                  }

                }
              }
            }
          }
        }
      }
    }
  }

}
