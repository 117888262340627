.pane-taxonomy-term-field-original-name {
  color: $color-red-1;
  font-size: 24px;
  font-weight: 700;
  line-height: inherit;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 40px;

  @include breakpoint ($desk) {
    font-size: 30px;
  }

}
