.menu-links {
  position: relative;
  z-index: 2;

  // Triggers variables.
  $widthMob: 21px;
  $width: 30px;
  $triggerWidhtMob: 53px;
  $triggerHeightMob: 53px;
  $triggerWidht: 77px;
  $triggerHeight: 77px;
  $line-size: 1px;
  $line-color: white;
  $line-radius: $line-size;
  $to-translate: (21px - $line-size) / 2;
  $bounce: cubic-bezier(.87, -.50, .19, 1.50);

  .hidden-element {
    visibility: hidden;
  }

  .country {
    display: table;
    float: left;
    background: white;
    height: $triggerHeightMob;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;

    @include breakpoint ($desk) {
      height: $triggerHeight;
    }

    .inner-content {
      display: table-cell;
      vertical-align: middle;
      padding: 0 10px;
    }

  }

  // Search trigger.
  .search {
    background: #E8E8E8;
    display: block;
    width: $triggerWidhtMob;
    height: $triggerHeightMob;
    float: left;
    overflow: hidden;
    position: relative;

    @include breakpoint ($desk) {
      width: $triggerWidht;
      height: $triggerHeight;
    }

    // Search animation variables.
    $bgDark: $color-grey-1;
    $border: 4px solid #E8E8E8;
    $easing: cubic-bezier(.25, 1.7, .35, .8);
    $duration: 0.6s;

    // Active state
    &.open {

      &:before {
        transition: all 1s ease-in-out;
        right: 0;
      }

      .magnified-icon {
        z-index: 3;

        .svg-icon {
          z-index: -2;
        }

        .css-icon {
          z-index: -1;

          .border {

            &.border-01 {
              bottom: 0;
              right: -4px;
              transition: right .3s ease-in, bottom .3s ease-in;
            }

            &.border-02 {
              top: 0;
              right: 0;
              transition: right .7s ease-in;
            }

            &.border-03 {
              top: 50%;
              left: 0;
              transition: top 1s ease-in;
            }

          }
        }

      }

      .close-icon {

        .line-a {
          opacity: 1;
          transform: rotate(45deg) translate3d(0, 0, 0);
          transition: opacity .5s ease-in-out .4s,
          transform .4s ease-in-out .4s;
        }

        .line-b {
          opacity: 1;
          transform: rotate(-45deg) translate3d(0, 0, 0);
          transition: opacity .5s ease-in-out .5s,
          transform .5s ease-in-out .5s;
        }

      }

    }

    // Bg active
    &:before {
      content: "";
      display: inline-block;
      width: 100%;
      height: 100%;
      background: $bgDark;
      transition: all .8s ease-in-out;
      z-index: 4;
      position: relative;
      top: 0;
      right: -100%;
    }

    .magnified-icon {
      width: 22px;
      height: 22px;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;

      @include breakpoint ($desk) {
        width: 32px;
      }

      .svg-icon {
        width: 100%;
        height: 100%;
        background: url(image-url("icons/lens.svg")) no-repeat center;
        background-size: 19px 100%;
        z-index: -1;
        text-indent: -10000em;
        position: absolute;
        top: 0;
        left: 1px;
      }

      .css-icon {
        width: 23px;
        height: 23px;
        overflow: hidden;
        position: relative;
        z-index: 0;
        margin: 0 auto;

        .border {
          background: #E8E8E8;
          position: absolute;

          &.border-01 {
            width: 60%;
            height: 60%;
            transition: right 1s ease-in, bottom 1s ease-in;
            bottom: -11px;
            right: -11px;
          }

          &.border-02 {
            width: 100%;
            height: 60%;
            transition: right .7s ease-in;
            top: 0;
            right: -100%;
          }

          &.border-03 {
            width: 60%;
            height: 50%;
            transition: top .3s ease-in;
            top: -60%;
            left: 0;
          }

        }
      }

    }

    // Close
    .close-icon {
      width: $widthMob;
      height: 24px;
      display: inline-block;
      transform: translate(-50%, -50%);
      z-index: 5;
      position: absolute;
      top: 50%;
      left: 50%;

      @include breakpoint ($desk) {
        width: $width;
      }

      .menu-line {
        width: 100%;
        height: $line-size;
        background-color: $line-color;
        border-radius: $line-radius;
        pointer-events: none;
        position: absolute;
        left: 0;
      }

      .line-a {
        transform: rotate(45deg) translate3d(-$widthMob, 0, 0);
        transition: opacity .5s ease-in-out .5s,
        transform .5s ease-in-out .5s;

        @include breakpoint ($desk) {
          transform: rotate(45deg) translate3d(-$width, 0, 0);
        }

      }

      .line-b {
        transform: rotate(-45deg) translate3d($widthMob, 0, 0);
        transition: opacity .5s ease-in-out,
        transform .5s ease-in-out;

        @include breakpoint ($desk) {
          transform: rotate(-45deg) translate3d($width, 0, 0);
        }

      }

      .line-a,
      .line-b {
        opacity: 0;
        top: $to-translate;
        transition: opacity .5s ease-in-out,
        transform .5s ease-in-out;
      }

    }

  }

  // Main menu trigger.
  .main-menu {
    width: $triggerWidhtMob;
    height: $triggerHeightMob;
    background: $color-red-1;
    float: left;
    cursor: pointer;
    overflow: hidden;
    position: relative;

    @include breakpoint ($desk) {
      width: $triggerWidht;
      height: $triggerHeight;
    }

    &.open {

      &:before {
        transition: all 1s ease-in-out;
        right: 0;
      }

      .menu-icon .menu-line {

        &.line-1 {
          width: 0;
          transition: width .3s ease-in-out;
        }

        &.line-2 {
          width: 0;
          transition: width .3s ease-in-out .1s;
        }

        &.line-3 {
          width: 0;
          transition: width .3s ease-in-out .3s;
        }

        &.line-4 {
          opacity: 1;
          transform: rotate(45deg) translate3d(0, 0, 0);
          transition: opacity .3s ease-in-out .3s,
          transform .3s ease-in-out .3s;
        }

        &.line-5 {
          opacity: 1;
          transform: rotate(-45deg) translate3d(0, 0, 0);
          transition: opacity .3s ease-in-out .5s,
          transform .3s ease-in-out .5s;
        }

      }

    }

    &:before {
      content: "";
      display: inline-block;
      width: 100%;
      height: 100%;
      background: $color-grey-1;
      transition: all 1.2s ease-in-out;
      position: absolute;
      top: 0;
      right: -100%;
    }

    .menu-icon {
      width: $widthMob;
      height: 20px;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;

      @include breakpoint ($desk) {
        width: $width;
      }

      .menu-line {
        width: 100%;
        height: $line-size;
        background-color: $line-color;
        border-radius: $line-radius;
        pointer-events: none;
        position: absolute;
        left: 0;

        &.line-1 {
          transition: width .3s ease-in-out .7s;
          top: 0;
        }

        &.line-2 {
          transition: width .3s ease-in-out .5s;
          margin: auto;
          top: 0;
          bottom: 0;
        }

        &.line-3 {
          transition: width .3s ease-in-out .3s;
          bottom: 0;
        }

        &.line-4 {
          transform: rotate(45deg) translate3d(-$widthMob, 0, 0);
          transition: opacity .4s ease-in-out .4s,
          transform .4s ease-in-out .4s;

          @include breakpoint ($desk) {
            transform: rotate(45deg) translate3d(-$width, 0, 0);
          }

        }

        &.line-5 {
          transform: rotate(-45deg) translate3d($widthMob, 0, 0);
          transition: opacity .4s ease-in-out,
          transform .4s ease-in-out;

          @include breakpoint ($desk) {
            transform: rotate(-45deg) translate3d($width, 0, 0);
          }

        }

        &.line-4,
        &.line-5 {
          opacity: 0;
          top: $to-translate;
          transition: opacity .4s ease-in-out,
          transform .4s ease-in-out;
        }

      }

    }

    span {
      visibility: hidden;
    }

  }

}