.block-search-api-sorts {
  max-width: 225px;
  background: #d6d6d6;
  margin-left: auto;
  margin-bottom: 30px;
  position: relative;

  &:hover {

    > h2:after {
      transform: rotate(0deg);
    }

    .content {
      display: block;
    }

  }

  > h2 {
    font-size: 14px;
    line-height: 1;
    font-weight: 400;
    cursor: pointer;
    padding: 10px 38px 10px 15px;
    margin: 0;
    position: relative;

    &:after {
      content: '';
      display: inline-block;
      width: 15px;
      height: 10px;
      background: url(image-url("icons/chevron-black-bold.svg")) no-repeat center;
      background-size: 13px;
      transform: rotate(180deg);
      transition: all .4s;
      position: absolute;
      top: 12px;
      right: 15px;
    }

  }

  > .content {
    display: none;
    width: 100%;
    background: #d6d6d6;
    z-index: 1;
    position: absolute;
    top: 100%;
    left: 0;

    .search-api-sorts {
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        margin: 0;

        a {
          display: block;
          font-size: 14px;
          font-family: $font-exo2;
          font-weight: 400;
          line-height: 1;
          padding: 8px 15px;

          &:hover {
            background: $color-grey-4;
          }

          img {
            margin-left: 4px;
          }

        }
      }
    }
  }

}
