.block-facetapi {
  margin-bottom: 30px;

  // As accordion, ex.:/books-catalog
  &.as-accordion {
    margin-bottom: 19px;

    // Custom style for this facet.
    &.etiquetas {

      &.hide {
        display: none;
      }

      .facetapi-inactive {
        display: none;
      }

      .content .facetapi-limit-link {
        display: none;
      }

    }

    &.open {

      > h2:after {
        transform: rotate(0deg);
      }

      .content {
        display: block;
      }

    }

    > h2 {
      display: inline-block;
      font-size: 25px;
      cursor: pointer;
      margin: 0 0 0.2em;
      position: relative;

      &:after {
        content: '';
        display: inline-block;
        width: 15px;
        height: 12px;
        background: url(image-url("icons/chevron-red-bold.svg")) no-repeat center;
        background-size: 13px;
        transform: rotate(180deg);
        transition: all .4s;
        margin-left: 13px;
      }

    }

    .content {
      display: none;

      .facetapi-active-item {
        color: #949494;
        font-size: 14px;
        font-weight: 500;
        border-bottom: none;
        padding: 5px 0;
        position: relative;

        a.facetapi-active {
          width: 14px;
          height: 14px;
          text-indent: -1000em;
          overflow: hidden;
          padding: 0;
          margin-right: 7px;
          margin-top: 2px;
          position: relative;

          &:hover {

            &:before {
              background: $color-grey-1;
            }

          }

          &:before {
            background: $color-grey-2;
            border: 1px solid $color-grey-2;
            margin: 0;
            position: absolute;
            top: 0;
            left: 0;
          }

          &:after {
            content: "";
            display: inline-block;
            width: 4px;
            height: 10px;
            border-right: 1px solid white;
            border-bottom: 1px solid white;
            transform: rotate(45deg);
            position: absolute;
            top: 0;
            left: 5px;
          }

        }

      }

      .facetapi-facetapi-links {

        a {
          font-size: 14px;
          font-weight: 400;
          border-bottom: none;
          padding: 5px 0;

          &:before {
            content: "";
            display: inline-block;
            width: 14px;
            height: 14px;
            border: 1px solid #949494;
            vertical-align: top;
            margin-top: 1px;
            margin-right: 7px;
          }

        }

      }

      .facetapi-limit-link {
        display: inline-block;
        color: $color-red-1;
        font-size: 14px;
        font-family: $font-exo2;
        text-transform: uppercase;
        transition: all .4s;
        float: right;

        &:hover {
          color: $color-red-3;
        }

      }
    }

  }

  &:last-child {
    margin-bottom: 0;
  }

  > h2 {
    color: $color-red-1;
    font-size: 30px;
    font-weight: 700;
    margin: 0 0 .5em;
  }

  .content ul {
    margin: 0;

    li {
      color: #949494;
      font-weight: 700;
      list-style: none;
      margin: 0;

      &:last-child a {
        border-bottom: none;
      }

      &.facetapi-active-item {
        color: $color-red-1;
        border-bottom: 1px solid #949494;

        a {
          border-bottom: none;
        }

      }

      a {
        display: block;
        color: #949494;
        font-size: 14px;
        border-bottom: 1px solid #949494;
        padding: 8px 0 9px;

        @include breakpoint ($desk) {
          font-size: 16px;
          padding: 7px 0 10px;
        }

        &.facetapi-active {
          display: inline-block;
          color: $color-red-1;
          padding-right: 5px;
        }

      }
    }
  }

}
