.paragraphs-item-list-icon-text-item {

  .content {
    width: 100%;
    display: table;

    > div {
      display: table-cell;
      vertical-align: middle;
    }

    .field-name-field-list-icon-text-item-icon {
      width: 80px;

      img {
        max-width: 80%;
      }

    }

    .field-name-field-list-icon-text-item-text {
      font-size: 16px;
      font-weight: 700;

      @include breakpoint ($desk) {
        font-size: 20px;
      }

    }
  }

}
