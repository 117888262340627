.paragraphs-item-title {

  .field-items {

    * {
      color: $color-red-1;
      font-size: 30px;
      text-transform: uppercase;
    }

  }

}
