.paragraphs-item-type-spacer {

  .paragraph-preview {
    margin-bottom: 20px;
  }

  .field-name-field-spacer-height {

    .form-type-radios > label {
      margin-bottom: 5px;
    }

    .form-radios {

      > div {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        input {
          margin-top: -2px;
        }

      }
    }
  }

}

.layout-componer-container:not(.closed) .layout-content > .paragraphs-item-spacer .spacer-desc {
  display: block;
}

.paragraphs-item-spacer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .spacer-desc {
    display: none;
  }

}
